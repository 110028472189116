import React, { useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';

import { useHistory } from 'react-router-dom';

import { CreateNewAsset, UploadSignDocuments } from 'src/services/api';
import toast from 'react-hot-toast';

import ImageUpload from '../components/ImageUpload';
import DocumentsUpload from '../components/DocumentsUpload';

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Spinner,
  Label,
  Input,
  Button,
  FormFeedback,
  Form
} from 'reactstrap';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const CreateAsset = () => {
  const [editorLoading, setEditorLoading] = useState(true);
  const editorRef = useRef(null);

  const History = useHistory();

  document.title = 'Create Asset | Goyield Backoffice';

  const paymentPeriod = [
    'Acquisition',
    'Qualification and approval',
    'Agreement',
    'Payment',
    'Distribution'
  ]

  const riskLevel = [
    'mb',
    'b',
    'm',
    'ma',
    'a',
    'max'
  ]

  const type = [
    'precatorio',
  ]

  const riskType = [
    'Judicial Publico',
  ]

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      subtitle: '',
      devedor: '',
      code: '',
      type: '',
      image: [],
      token: {
        name: '',
        symbol: '',
        supply: 0,
      },
      values: {
        faceValue: 0,
        initialPriceToken: 0
      },
      schedule: {
        issuingDate: '',
        distributionClosingDate: '',
        dueDate: ''
      },
      fees: {
        distribution: 0,
        administration: 0,
        performance: {
          worstToBase: 0,
          baseToOptimistic: 0,
          aboveOptimistic: 0
        },
        placement: 0,
      },
      yields: {
        optimistic: 0,
        base: 0,
        worst: 0,
      },
      text: {
        assetOwner: '',
        summary: '',
        profitability: '',
        dueDiligence: '',
        paymentPeriod: '',
        riskType: '',
        riskLevel: '',
        profitabilityPerc: 0,
        minQuantity: 0,
        minInvestment: 0,
        returnOptMonths: '',
        returnBasMonths: '',
        returnPesMonths: '',
        whyBuyToken: '',
        riskDesc: ''
      },
      active: true,
      visible: true,
      reserving: true
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      subtitle: Yup.string().required('Required'),
      devedor: Yup.string().required('Required'),
      code: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      token: Yup.object({
        name: Yup.string().required('Required'),
        symbol: Yup.string().required('Required').min(3, 'Must be at least 3 characters long').max(4, 'Must be maximum 4 characters long'),
        supply: Yup.number().required('Required').moreThan(0, 'Must be at least one.'),
      }),
      values: Yup.object({
        faceValue: Yup.number().required('Required.'),
        initialPriceToken: Yup.number().required('Required.'),
      }),
      schedule: Yup.object({
        issuingDate: Yup.date().required('Required.'),
        distributionClosingDate: Yup.date().required('Required.'),
        dueDate: Yup.date().required('Required.'),
      }),
      fees: Yup.object({
        distribution: Yup.number().required('Required.'),
        administration: Yup.number().required('Required.'),
        performance: Yup.object({
          worstToBase: Yup.number().required('Required.'),
          baseToOptimistic: Yup.number().required('Required.'),
          aboveOptimistic: Yup.number().required('Required.'),
        }),
        placement: Yup.number().required('Required.'),
      }),
      yields: Yup.object({
        optimistic: Yup.number().required('Required.'),
        base: Yup.number().required('Required.'),
        worst: Yup.number().required('Required.'),
      }),
      text: Yup.object({
        assetOwner: Yup.string().required('Required'),
        summary: Yup.string().required('Required'),
        profitability: Yup.string().required('Required'),
        dueDiligence: Yup.string().required('Required'),
        paymentPeriod: Yup.string().required('Required').oneOf(["Acquisition", "Qualification and approval", "Agreement", "Payment", "Distribution"]),
        riskType: Yup.string().required('Required'),
        riskLevel: Yup.string().required('Required').oneOf(["mb", "b", "m", "ma", "a", "max"]),
        profitabilityPerc: Yup.number().required('Required'),
        minQuantity: Yup.number().required('Required'),
        minInvestment: Yup.number().required('Required'),
        returnOptMonths: Yup.string().required('Required'),
        returnBasMonths: Yup.string().required('Required'),
        returnPesMonths: Yup.string().required('Required'),
        whyBuyToken: Yup.string().required('Required'),
        riskDesc: Yup.string().required('Required'),
      }),
    }),
    onSubmit: async (values) => {
      const ToastID = toast.loading('Please wait, this might take a while...');
      let description = '';

      let formData = new FormData();
      
      if (editorRef.current) {
        // @ts-expect-error
        description = editorRef.current.getContent();
      }
      
      try {
        const ISOIssuingDate = new Date(values.schedule?.issuingDate).toISOString();
        const ISODistributionClosingDate = new Date(values.schedule?.distributionClosingDate).toISOString();
        const ISODueDate = new Date(values.schedule?.dueDate).toISOString();
        
        for (const property in values) {
          //@ts-expect-error
          if (typeof values[property] === 'object' && values[property] !== null) {
            //@ts-expect-error
            formData.append(property, JSON.stringify(values[property]));
          } else {
            //@ts-expect-error
            formData.append(property, values[property]);
          }
        }
        
        formData.append('description', description);
        // formData.set('issuingDate', ISOIssuingDate);
        // formData.set('distributionClosingDate', ISODistributionClosingDate);
        formData.set('image', values.image[0]);
        
        const response = await CreateNewAsset(formData);

        toast.success(response.message ?? 'Created new asset');
        History.push({ pathname: '/assets/' + response.data._id });
      } catch (error: any) {
        toast.error(error?.response?.data?.message ?? 'An error has occurred');
      }
      toast.dismiss(ToastID);
      // validation.resetForm();
    }
  });

  console.log(validation)
  console.log('api key tiny', process.env.REACT_APP_API_KEY_TINY)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Assets" breadcrumbItem="Create" />

          <Row>
            <Col xl={12}>
              <Card>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <CardHeader>
                    <Row>
                      <Col>
                        <h4 className="card-title">Create a new asset</h4>
                        <p className="card-title-desc">
                          All fields are required
                        </p>
                      </Col>
                      <Col>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            disabled={validation.isSubmitting}
                            color="primary"
                            type="submit"
                          >
                            Create new asset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Asset */}
                    <FormGroup>
                      <h3>Asset</h3>
                      <Row>
                        <Col className="py-3">
                          <Label htmlFor="validationCustom01">Name</Label>
                          <Input
                            name="name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col className="py-3">
                          <Label htmlFor="validationCustom23">Subtitle</Label>
                          <Input
                            name="subtitle"
                            type="text"
                            className="form-control"
                            id="validationCustom23"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subtitle || ''}
                            invalid={
                              validation.touched.subtitle && validation.errors.subtitle
                                ? true
                                : false
                            }
                          />
                          {validation.touched.subtitle && validation.errors.subtitle ? (
                            <FormFeedback type="invalid">
                              {validation.errors.subtitle}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col className="py-3">
                          <Label htmlFor="validationCustom23">Devedor (Internal field)</Label>
                          <Input
                            name="devedor"
                            type="text"
                            className="form-control"
                            id="validationCustom23"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.devedor || ''}
                            invalid={
                              validation.touched.devedor && validation.errors.devedor
                                ? true
                                : false
                            }
                          />
                          {validation.touched.devedor && validation.errors.devedor ? (
                            <FormFeedback type="invalid">
                              {validation.errors.devedor}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row>
                        <Col className="py-3">
                          <Label>Image</Label>
                          <ImageUpload
                            setFieldValue={validation.setFieldValue}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="py-3">
                          <Label htmlFor="validationCustom02">
                            Description
                          </Label>
                          {editorLoading && (
                            <Card
                              style={{
                                display: 'grid',
                                placeContent: 'center',
                                padding: '10px 0'
                              }}
                            >
                              <Spinner color="primary"></Spinner>
                            </Card>
                          )}
                          <Editor
                            id="validationCustom02"
                            apiKey={process.env.REACT_APP_API_KEY_TINY}
                            onInit={(evt, editor) => {
                              // @ts-expect-error
                              editorRef.current = editor;
                              setEditorLoading(false);
                            }}
                            init={{
                              height: 300,
                              menubar: 'edit format',
                              statusbar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | hr',
                              content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="my-4" />
                    {/* Token */}
                    <FormGroup>
                      <h3>Token</h3>
                      <Row className="py-3">
                        <Col md="3">
                          <Label htmlFor="validationCustom04">Name</Label>
                          <Input
                            name="token[name]"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.token?.name || ''}
                            invalid={
                              validation.touched.token?.name &&
                              validation.errors.token?.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.token?.name &&
                          validation.errors.token?.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.token?.name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md="3">
                          <Label htmlFor="validationCustom05">Symbol</Label>
                          <Input
                            name="token[symbol]"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.token?.symbol || ''}
                            invalid={
                              validation.touched.token?.symbol &&
                              validation.errors.token?.symbol
                                ? true
                                : false
                            }
                          />
                          {validation.touched.token?.symbol &&
                          validation.errors.token?.symbol ? (
                            <FormFeedback type="invalid">
                              {validation.errors.token?.symbol}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md="3">
                          <Label htmlFor="validationCustom05">Code</Label>
                          <Input
                            name="code"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.code || ''}
                            invalid={
                              validation.touched.code &&
                              validation.errors.code
                                ? true
                                : false
                            }
                          />
                          {validation.touched.code &&
                          validation.errors.code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.code}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md="3">
                          <Label htmlFor="validationCustom05">Type</Label>
                          <Input
                            name="type"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.type || ''}
                            invalid={
                              validation.touched.type &&
                              validation.errors.type
                                ? true
                                : false
                            }
                          />
                          {validation.touched.type &&
                          validation.errors.type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.type}
                            </FormFeedback>
                          ) : null}
                        </Col>
{/*                         <Col md="3">
                          <Label htmlFor="validationCustom13">
                            Select the type where the token is:
                          </Label>
                          <select
                            name="type"
                            id="validationCustom19"
                            className={`form-control`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.type || ""}
                          >
                            <option value={undefined} selected>
                              Select a Type
                            </option>
                            {type.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                          {validation.touched.type && validation.errors.type && (
                            <div className="invalid-feedback">{validation.errors.type}</div>
                          )}
                        </Col> */}
                      </Row>
                      <Row className="py-3">
                        <Col md={3}>
                          <Label htmlFor="validationCustom03">
                            Token unit price
                          </Label>
                          <Input
                            name="values[initialPriceToken]"
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.values?.initialPriceToken || ''}
                            invalid={
                              validation.touched.values?.initialPriceToken &&
                              validation.errors.values?.initialPriceToken
                                ? true
                                : false
                            }
                          />
                          {validation.touched.values?.initialPriceToken &&
                          validation.errors.values?.initialPriceToken ? (
                            <FormFeedback type="invalid">
                              {validation.errors.values?.initialPriceToken}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <Label htmlFor="validationCustom03">
                            Face value
                          </Label>
                          <Input
                            name="values[faceValue]"
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.values?.faceValue || ''}
                            invalid={
                              validation.touched.values?.faceValue &&
                              validation.errors.values?.faceValue
                                ? true
                                : false
                            }
                          />
                          {validation.touched.values?.faceValue &&
                          validation.errors.values?.faceValue ? (
                            <FormFeedback type="invalid">
                              {validation.errors.values?.faceValue}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <Label htmlFor="validationCustom06">Supply</Label>
                          <Input
                            name="token[supply]"
                            type="text"
                            className="form-control"
                            id="validationCustom06"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.token?.supply || ''}
                            invalid={
                              validation.touched.token?.supply &&
                              validation.errors.token?.supply
                                ? true
                                : false
                            }
                          />
                          {validation.touched.token?.supply &&
                          validation.errors.token?.supply ? (
                            <FormFeedback type="invalid">
                              {validation.errors.token?.supply}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <Label htmlFor="validationCustom06">Min Quantity</Label>
                          <Input
                            name="text[minQuantity]"
                            type="text"
                            className="form-control"
                            id="validationCustom25"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.minQuantity || ''}
                            invalid={
                              validation.touched?.text?.minQuantity &&
                              validation.errors?.text?.minQuantity
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.minQuantity &&
                          validation.errors?.text?.minQuantity ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.minQuantity}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className='py-3'>
                        <Col md={4}>
                          <Label htmlFor="validationCustom06">Min Investment</Label>
                          <Input
                            name="text[minInvestment]"
                            type="text"
                            className="form-control"
                            id="validationCustom26"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.minInvestment || ''}
                            invalid={
                              validation.touched?.text?.minInvestment &&
                              validation.errors?.text?.minInvestment
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.minInvestment &&
                          validation.errors?.text?.minInvestment ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.minInvestment}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom06">Profitability Percentage</Label>
                          <Input
                            name="text[profitabilityPerc]"
                            type="text"
                            className="form-control"
                            id="validationCustom24"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.profitabilityPerc || ''}
                            invalid={
                              validation.touched?.text?.profitabilityPerc &&
                              validation.errors?.text?.profitabilityPerc
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.profitabilityPerc &&
                          validation.errors?.text?.profitabilityPerc ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.profitabilityPerc}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom06">Asset owner</Label>
                          <Input
                            name="text[assetOwner]"
                            type="text"
                            className="form-control"
                            id="validationCustom24"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.assetOwner || ''}
                            invalid={
                              validation.touched?.text?.assetOwner &&
                              validation.errors?.text?.assetOwner
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.assetOwner &&
                          validation.errors?.text?.assetOwner ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.assetOwner}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className='py-3'>
                        <Col md={12}>
                          <Label htmlFor="validationCustom06">Why buy this token?</Label>
                          <Input
                            name="text[whyBuyToken]"
                            type="textarea"
                            className="form-control"
                            id="validationCustom33"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.whyBuyToken || ''}
                            invalid={
                              validation.touched?.text?.whyBuyToken &&
                              validation.errors?.text?.whyBuyToken
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.whyBuyToken &&
                          validation.errors?.text?.whyBuyToken ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.whyBuyToken}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="my-4" />
                    {/* Token Status */}
                    <FormGroup>
                    <h3>Token Status</h3>
                      <Row className='py-3'>
                        <Col md={4}>
                          <Label htmlFor="validationCustom07">
                            Start validity date
                          </Label>
                          <Input
                            name="schedule[issuingDate]"
                            type="date"
                            className="form-control"
                            id="validationCustom07"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.schedule?.issuingDate || ''}
                            invalid={
                              validation.touched.schedule?.issuingDate &&
                              validation.errors.schedule?.issuingDate
                                ? true
                                : false
                            }
                          />
                          {validation.touched.schedule?.issuingDate &&
                          validation.errors.schedule?.issuingDate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.schedule?.issuingDate}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom08">
                            End validity date
                          </Label>
                          <Input
                            name="schedule[distributionClosingDate]"
                            type="date"
                            className="form-control"
                            id="validationCustom08"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.schedule?.distributionClosingDate || ''}
                            invalid={
                              validation.touched.schedule?.distributionClosingDate &&
                              validation.errors.schedule?.distributionClosingDate
                                ? true
                                : false
                            }
                          />
                          {validation.touched.schedule?.distributionClosingDate &&
                          validation.errors.schedule?.distributionClosingDate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.schedule?.distributionClosingDate}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom08">
                            Due date
                          </Label>
                          <Input
                            name="schedule[dueDate]"
                            type="date"
                            className="form-control"
                            id="validationCustom08"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.schedule?.dueDate || ''}
                            invalid={
                              validation.touched.schedule?.dueDate &&
                              validation.errors.schedule?.dueDate
                                ? true
                                : false
                            }
                          />
                          {validation.touched.schedule?.dueDate &&
                          validation.errors.schedule?.dueDate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.schedule?.dueDate}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={1} lg={12}>
                          <div className="form-check">
                            <Input
                              name="active"
                              type="checkbox"
                              className="form-check-input"
                              id="activeCheck"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              checked={validation.values.active}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="activeCheck"
                            >
                              Active
                            </Label>
                          </div>
                        </Col>
                        <Col xl={1} lg={12}>
                          <div className="form-check">
                            <Input
                              name="visible"
                              type="checkbox"
                              className="form-check-input"
                              id="visibleCheck"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              checked={validation.values.visible}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="visibleCheck"
                            >
                              Visible
                            </Label>
                          </div>
                        </Col>
                        <Col xl={3} lg={12}>
                          <div className="form-check">
                            <Input
                              name="reserving"
                              type="checkbox"
                              className="form-check-input"
                              id="reservingCheck"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              checked={validation.values.reserving}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="reservingCheck"
                            >
                              Pre order mode
                            </Label>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="my-4" />
                    {/* Summary */}
                    <FormGroup>
                    <h3>Summary</h3>
                    <Row className="py-3">
                        <Col md="4">
                          <Label htmlFor="validationCustom09">
                            Summary
                          </Label>
                          <Input
                            name="text[summary]"
                            type="textarea"
                            id="validationCustom09"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.summary || ''}
                            invalid={
                              validation.touched?.text?.summary &&
                              validation.errors?.text?.summary
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.summary &&
                          validation.errors?.text?.summary ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.summary}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom10">Profitability</Label>
                          <Input
                            name="text[profitability]"
                            type="textarea"
                            className="form-control"
                            id="validationCustom10"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.profitability || ''}
                            invalid={
                              validation.touched?.text?.profitability &&
                              validation.errors?.text?.profitability
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.profitability &&
                          validation.errors?.text?.profitability ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.profitability}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom11">
                            Due Diligence
                          </Label>
                          <Input
                            name="text[dueDiligence]"
                            type="textarea"
                            className="form-control"
                            id="validationCustom11"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.dueDiligence || ''}
                            invalid={
                              validation.touched?.text?.dueDiligence &&
                              validation.errors?.text?.dueDiligence
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.dueDiligence &&
                          validation.errors?.text?.dueDiligence ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.dueDiligence}
                            </FormFeedback>
                          ) : null}
                        </Col>
                    </Row>
                    </FormGroup>
                    <hr className="my-4" />
                    {/* Yields and Returns */}
                    <FormGroup>
                    <h3>Yields and Returns</h3>
                    <Row className="py-3">
                      <Col md={4}>
                        <Label htmlFor="validationCustom13">
                          Estimated Yield: Optimistic
                        </Label>
                        <Input
                          name="yields[optimistic]"
                          type="text"
                          id="validationCustom13"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.yields?.optimistic || ''}
                          invalid={
                            validation.touched?.yields?.optimistic &&
                            validation.errors?.yields?.optimistic
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.yields?.optimistic &&
                        validation.errors?.yields?.optimistic ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.yields?.optimistic}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label htmlFor="validationCustom14">Estimated Yield: Base</Label>
                        <Input
                          name="yields[base]"
                          type="text"
                          className="form-control"
                          id="validationCustom14"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.yields?.base || ''}
                          invalid={
                            validation.touched?.yields?.base &&
                            validation.errors?.yields?.base
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.yields?.base &&
                        validation.errors?.yields?.base ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.yields?.base}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label htmlFor="validationCustom15">
                          Estimated Yield: Worst
                        </Label>
                        <Input
                          name="yields[worst]"
                          type="text"
                          className="form-control"
                          id="validationCustom15"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.yields?.worst || ''}
                          invalid={
                            validation.touched?.yields?.worst &&
                            validation.errors?.yields?.worst
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.yields?.worst &&
                        validation.errors?.yields?.worst ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.yields?.worst}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    {/* RETURNS LEVATI */}
                    <Row className="py-3">
{/*                       <Col md={2}>
                        <Label htmlFor="validationCustom16">
                          Estimated Return: Optimistic
                        </Label>
                        <Input
                          name="text[estimatedReturn][optimistic]"
                          type="text"
                          id="validationCustom16"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.estimatedReturn?.optimistic || ''}
                          invalid={
                            validation.touched?.text?.estimatedReturn?.optimistic &&
                            validation.errors?.text?.estimatedReturn?.optimistic
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.text?.estimatedReturn?.optimistic &&
                        validation.errors?.text?.estimatedReturn?.optimistic ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.text?.estimatedReturn?.optimistic}
                          </FormFeedback>
                        ) : null}
                      </Col> */}
                      <Col md={4}>
                        <Label htmlFor="validationCustom18">
                          Months of Return: Ottimistic
                        </Label>
                        <Input
                          name="text[returnOptMonths]"
                          type="text"
                          className="form-control"
                          id="validationCustom47"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.returnOptMonths || ''}
                          invalid={
                            validation.touched?.text?.returnOptMonths &&
                            validation.errors?.text?.returnOptMonths
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.text?.returnOptMonths &&
                        validation.errors?.text?.returnOptMonths ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.text?.returnOptMonths}
                          </FormFeedback>
                        ) : null}
                      </Col>
{/*                       <Col md={2}>
                        <Label htmlFor="validationCustom17">Estimated Return: Base</Label>
                        <Input
                          name="text[estimatedReturn][base]"
                          type="text"
                          className="form-control"
                          id="validationCustom17"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.estimatedReturn?.base || ''}
                          invalid={
                            validation.touched?.text?.estimatedReturn?.base &&
                            validation.errors?.text?.estimatedReturn?.base
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.text?.estimatedReturn?.base &&
                        validation.errors?.text?.estimatedReturn?.base ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.text?.estimatedReturn?.base}
                          </FormFeedback>
                        ) : null}
                      </Col> */}
                      <Col md={4}>
                        <Label htmlFor="validationCustom18">
                          Months of Return: Base
                        </Label>
                        <Input
                          name="text[returnBasMonths]"
                          type="text"
                          className="form-control"
                          id="validationCustom28"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.returnBasMonths || ''}
                          invalid={
                            validation.touched?.text?.returnBasMonths &&
                            validation.errors?.text?.returnBasMonths
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.text?.returnBasMonths &&
                        validation.errors?.text?.returnBasMonths ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.text?.returnBasMonths}
                          </FormFeedback>
                        ) : null}
                      </Col>
{/*                       <Col md={2}>
                        <Label htmlFor="validationCustom18">
                          Estimated Return: Pessimistic
                        </Label>
                        <Input
                          name="text[estimatedReturn][pessimistic]"
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.estimatedReturn?.pessimistic || ''}
                          invalid={
                            validation.touched?.text?.estimatedReturn?.pessimistic &&
                            validation.errors?.text?.estimatedReturn?.pessimistic
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.text?.estimatedReturn?.pessimistic &&
                        validation.errors?.text?.estimatedReturn?.pessimistic ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.text?.estimatedReturn?.pessimistic}
                          </FormFeedback>
                        ) : null}
                      </Col> */}
                      <Col md={4}>
                        <Label htmlFor="validationCustom18">
                          Months of Return: Pessimistic
                        </Label>
                        <Input
                          name="text[returnPesMonths]"
                          type="text"
                          className="form-control"
                          id="validationCustom29"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.returnPesMonths || ''}
                          invalid={
                            validation.touched?.text?.returnPesMonths &&
                            validation.errors?.text?.returnPesMonths
                              ? true
                              : false
                          }
                        />
                        {validation.touched?.text?.returnPesMonths &&
                        validation.errors?.text?.returnPesMonths ? (
                          <FormFeedback type="invalid">
                            {validation.errors?.text?.returnPesMonths}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    </FormGroup>
                    <hr className="my-4" />
                    {/* Operation flows */}
                    <FormGroup>
                    <h3>Operation flows and Risks</h3>
                    <Row className="py-3">
                      <Col md="4">
                        <Label htmlFor="validationCustom13">
                          Select the step where the token is:
                        </Label>
                        <select
                          name="text[paymentPeriod]"
                          id="validationCustom19"
                          className={`form-control form-select`}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.paymentPeriod || ""}
                        >
                          <option value={undefined} selected>
                            Select a Step
                          </option>
                          {paymentPeriod.map((operation, index) => (
                            <option key={index} value={operation}>
                              {operation}
                            </option>
                          ))}
                        </select>
                        {validation.touched?.text?.paymentPeriod && validation.errors?.text?.paymentPeriod && (
                          <div className="invalid-feedback">{validation.errors?.text?.paymentPeriod}</div>
                        )}
                      </Col>
                        <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Risk type
                          </Label>
                          <Input
                            name="text[riskType]"
                            type="text"
                            id="validationCustom20"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.riskType || ''}
                            invalid={
                              validation.touched?.text?.riskType &&
                              validation.errors?.text?.riskType
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.riskType &&
                          validation.errors?.text?.riskType ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.riskType}
                            </FormFeedback>
                          ) : null}
                      </Col>
{/*                         <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Select the risk type where the token is:
                          </Label>
                          <select
                            name="text[riskType]"
                            id="validationCustom19"
                            className={`form-control`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.text?.riskType || ""}
                          >
                            <option value={undefined} selected>
                              Select a Risk Type
                            </option>
                            {riskType.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                          {validation.touched.text?.riskType && validation.errors.text?.riskType && (
                            <div className="invalid-feedback">{validation.errors.text?.riskType}</div>
                          )}
                        </Col> */}
                      <Col md="4">
                        <Label htmlFor="validationCustom13">
                          Select the risk level:
                        </Label>
                        <select
                          name="text[riskLevel]"
                          id="validationCustom21"
                          className={`form-control form-select`}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.text?.riskLevel || ""}
                        >
                          {riskLevel.map((operation, index) => (
                            <option key={index} value={operation}>
                              {operation}
                            </option>
                          ))}
                        </select>
                        {validation.touched?.text?.riskLevel && validation.errors?.text?.riskLevel && (
                          <div className="invalid-feedback">{validation.errors?.text?.riskLevel}</div>
                        )}
                      </Col>
                    </Row>
                    <Row className='py-3'>
                      <Col md="12">
                          <Label htmlFor="validationCustom13">
                            Risk description
                          </Label>
                          <Input
                            name="text[riskDesc]"
                            type="textarea"
                            id="validationCustom34"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.text?.riskDesc || ''}
                            invalid={
                              validation.touched?.text?.riskDesc &&
                              validation.errors?.text?.riskDesc
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.text?.riskDesc &&
                          validation.errors?.text?.riskDesc ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.text?.riskDesc}
                            </FormFeedback>
                          ) : null}
                      </Col>
                    </Row>
                    </FormGroup>
                    <hr className="my-4" />
                    {/* Fees */}
                    <FormGroup>
                    <h3>Fees</h3>
                    <Row className='py-3'>
                      <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Distribution fees %
                          </Label>
                          <Input
                            name="fees[distribution]"
                            type="text"
                            id="validationCustom22"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.fees?.distribution || ''}
                            invalid={
                              validation.touched?.fees?.distribution &&
                              validation.errors?.fees?.distribution
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.fees?.distribution &&
                          validation.errors?.fees?.distribution ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.fees?.distribution}
                            </FormFeedback>
                          ) : null}
                      </Col>
                      <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Administration fees %
                          </Label>
                          <Input
                            name="fees[administration]"
                            type="text"
                            id="validationCustom23"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.fees?.administration || ''}
                            invalid={
                              validation.touched?.fees?.administration &&
                              validation.errors?.fees?.administration
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.fees?.administration &&
                          validation.errors?.fees?.administration ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.fees?.administration}
                            </FormFeedback>
                          ) : null}
                      </Col>
                      <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Placement fees %
                          </Label>
                          <Input
                            name="fees[placement]"
                            type="text"
                            id="validationCustom30"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.fees?.placement || ''}
                            invalid={
                              validation.touched?.fees?.placement &&
                              validation.errors?.fees?.placement
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.fees?.placement &&
                          validation.errors?.fees?.placement ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.fees?.placement}
                            </FormFeedback>
                          ) : null}
                      </Col>
                    </Row>
                    <Row className='py-3'>
                      <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Performance: Worst to Base
                          </Label>
                          <Input
                            name="fees[performance][worstToBase]"
                            type="text"
                            id="validationCustom22"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.fees?.performance?.worstToBase || ''}
                            invalid={
                              validation.touched?.fees?.performance?.worstToBase &&
                              validation.errors?.fees?.performance?.worstToBase
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.fees?.performance?.worstToBase &&
                          validation.errors?.fees?.performance?.worstToBase ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.fees?.performance?.worstToBase}
                            </FormFeedback>
                          ) : null}
                      </Col>
                      <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Performance: Base to Optimistic
                          </Label>
                          <Input
                            name="fees[performance][baseToOptimistic]"
                            type="text"
                            id="validationCustom22"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.fees?.performance?.baseToOptimistic || ''}
                            invalid={
                              validation.touched?.fees?.performance?.baseToOptimistic &&
                              validation.errors?.fees?.performance?.baseToOptimistic
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.fees?.performance?.baseToOptimistic &&
                          validation.errors?.fees?.performance?.baseToOptimistic ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.fees?.performance?.baseToOptimistic}
                            </FormFeedback>
                          ) : null}
                      </Col>
                      <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Performance: Above Optimistic
                          </Label>
                          <Input
                            name="fees[performance][aboveOptimistic]"
                            type="text"
                            id="validationCustom22"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values?.fees?.performance?.aboveOptimistic || ''}
                            invalid={
                              validation.touched?.fees?.performance?.aboveOptimistic &&
                              validation.errors?.fees?.performance?.aboveOptimistic
                                ? true
                                : false
                            }
                          />
                          {validation.touched?.fees?.performance?.aboveOptimistic &&
                          validation.errors?.fees?.performance?.aboveOptimistic ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.fees?.performance?.aboveOptimistic}
                            </FormFeedback>
                          ) : null}
                      </Col>
                    </Row>
                    </FormGroup>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateAsset;
