import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-1.jpg';

// actions
import { editProfile, resetProfileFlag } from '../../store/actions';

const UserProfile = () => {
  const dispatch = useDispatch();

  const { error, success } = useSelector((state: any) => ({
    error: state.profile.error,
    success: state.profile.success
  }));

  const [email, setemail] = useState<string>('');
  const [name, setname] = useState<string>('');
  const [idx, setidx] = useState<number>(1);

  useEffect(() => {
    const authUser: any = localStorage.getItem('authUser');
    if (authUser) {
      const obj = JSON.parse(authUser);

      setname(`${obj.data.user.firstName} ${obj.data.user.lastName}`);
      setemail(obj.data.user.email);
      setidx(obj.data.user._id);

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      idx: idx || ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your UserName')
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  document.title = 'Profile | Goyield Backoffice';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="User" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
