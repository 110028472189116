import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Col, Input, Modal, ModalBody, Row } from 'reactstrap';
import { SendOtp, VerifyOTP } from 'src/services/api';

const CashoutModal = ({ show, onCashoutClick, onCloseClick, amount }: any) => {

  const userJson = localStorage.getItem('authUser'); // Recupera la stringa JSON dal localStorage

  // Controlla se userJson non è null e poi fa il parsing
  const user = userJson ? JSON.parse(userJson) : null;

  // Funzione per creare una versione mascherata del numero di telefono
  const maskPhoneNumber = (phoneNumber:any) => {
    if (!phoneNumber) return '';
    // Mostra solo le ultime 4 cifre del numero
    const visibleDigits = 4;
    const maskedSection = phoneNumber.slice(0, -visibleDigits).replace(/\d/g, '*');
    const visibleSection = phoneNumber.slice(-visibleDigits);
    return maskedSection + visibleSection;
  };

  const phonePrefix = user?.data?.user?.phone?.prefix ?? '';
  const phoneNumber = user?.data?.user?.phone?.number ?? '';
  const maskedPhone = maskPhoneNumber(phoneNumber);

  const [code, setCode] = useState('')
  const [validated, setValidated] = useState(false)

  const handleConfirmOTP = async (otp:any) => {
    try {
      const response = await VerifyOTP(otp);
      console.log(response.success)

      if (response.success) {
        setValidated(true)
        toast.success("Identity confirmed");
      }
      
    } catch (err) {
      console.log(err);
      toast.error("Identity not confirmed");
    }
  };

  const sendUserOTP = async () => {
    try {
      const response = await SendOtp();
      console.log(response)
      
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          {validated && (
            <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: '7em' }}
              />
              <h3>Are you sure? You are sending R$ {amount}</h3>
              <h6 style={{ fontWeight: 'lighter' }}>
                {"You won't be able to revert this!"}
              </h6>
            </div>
          </Col>
          )}
          {!validated && (
            <Col lg={12}>
            <div className="text-center">
              <h5>Confirm your identity, insert the OTP you received on: <span className='color-secondary'>+{phonePrefix}{maskedPhone}</span> </h5>
              <h6 style={{cursor:'pointer'}} className='text-decoration-underline' onClick={() => sendUserOTP()}>You didn't receive the SMS? Resend the otp.</h6>
              <Input
                type="text"
                className="my-3" // Margine in basso se necessario spazio extra
                placeholder="Insert the Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </Col>
          )}
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              {!validated ? (
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => handleConfirmOTP(code)}
                >
                  Confirm code
                </button>
              ) : (
                <button
                  type="button"
                  disabled={!validated}
                  className="btn btn-primary ms-2"
                  onClick={onCashoutClick}
                >
                  Send money
                </button>
              )}
              <button type="button" className="btn ms-2" onClick={onCloseClick}>
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CashoutModal.propTypes = {
  onCloseClick: PropTypes.func,
  onCashoutClick: PropTypes.func,
  show: PropTypes.any,
  amount: PropTypes.any
};

export default CashoutModal;
