import React from 'react';

import { Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';

interface AssetPriceHistoryProps {
  show: boolean;
  title: string;
  pricesList: any;
  onCloseClick: () => void;
}

const AssetPriceHistory = ({
  show,
  title,
  pricesList,
  onCloseClick
}: AssetPriceHistoryProps) => {
  console.log(pricesList);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center' }} data-priority="1">
                        Date
                      </th>
                      <th style={{ textAlign: 'center' }} data-priority="1">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pricesList.map((price: any) => (
                      <tr key={price._id} className="">
                        <td>{new Date(price.date).toUTCString()}</td>
                        <td>{price.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AssetPriceHistory;
