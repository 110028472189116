export function formatBalance(balance: number | undefined): string {
    if (balance === undefined) {
        return "Loading...";
    }

    // Determina se il numero è già decimale (contiene una parte decimale significativa)
    const isDecimal = balance % 1 !== 0;
    let amount = isDecimal ? balance : balance / 100;

    // Arrotonda a due cifre decimali e formatta con la virgola come separatore decimale e il punto per le migliaia
    let formattedNumber = amount.toFixed(2).replace('.', ',');

    // Aggiunge il separatore di migliaia
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedNumber;
}
