import axios from 'axios';
import { configure } from 'axios-hooks';

//apply base url for axios

const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL
});

configure({ axios: axiosApi, defaultOptions: { useCache: false } });

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const setAxiosInstanceAuth = (token: string | null) => {
  axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeAxiosInstanceAuth = () => {
  delete axiosApi.defaults.headers.common['Authorization'];
};

const currentUser = localStorage.getItem('authUser');

if (!!currentUser) {
  const parsedCurrentUser = JSON.parse(currentUser);
  setAxiosInstanceAuth(parsedCurrentUser.data.token);
}

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function postMultiPart(url: string, formData: any) {
  return axiosApi
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => response.data);
}

export async function putMultiPart(url: string, formData: any) {
  return axiosApi
    .put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => response.data);
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url: string, data: any, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
