// @ts-nocheck
import React, { useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Editor } from '@tinymce/tinymce-react';

import useAxios from 'axios-hooks';

import ImageUpload from '../components/ImageUpload';

import DeleteModal from 'src/components/Common/DeleteModal';

import {
  GET_CATEGORY,
  GET_ALL_ASSETS,
  DeleteCategory,
  EditCategory as EditCategoryApi,
  ToggleAssetFromCategory
} from 'src/services/api';

import toast from 'react-hot-toast';

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  FormFeedback,
  Form
} from 'reactstrap';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import AddAsset from '../components/AddAsset';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Icon from '@ailibs/feather-react-ts/dist/Icon';

const EditCategory = ({ match }: any) => {
  document.title = 'Edit Category | Goyield Backoffice';

  const ID = match.params.id;

  const History = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const [
    { data: categoryData, loading: categoryLoading, error: categoryError },
    refetchCategoryData
  ] = useAxios(`${GET_CATEGORY}${ID}/assets`);

  const [{ data: assetsData, loading: assetsLoading, error: assetsError }] =
    useAxios(`${GET_ALL_ASSETS}`);

  const [editorLoading, setEditorLoading] = useState(true);
  const [isAddAssetOpen, setIsAddAssetOpen] = useState(false);

  const editorRef = useRef(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      ...categoryData?.data,
      image: []
    },

    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      const { name } = values;
      const ToastID = toast.loading('Please wait...');

      let description = '';
      if (editorRef.current) {
        description = editorRef.current.getContent();
      }

      try {
        let formData = new FormData();

        formData.append('name', name);
        formData.append('description', description);
        formData.append('image', values.image[0]);

        const { message } = await EditCategoryApi(ID, formData);
        toast.success(message ?? 'Category edited successufully');
        await refetchCategoryData();
      } catch (error: any) {
        toast.error(error?.response?.data?.message ?? 'An error has occurred');
      }
      toast.dismiss(ToastID);
    }
  });

  const handleCategoryDelete = async () => {
    const ToastID = toast.loading('Please wait...');

    try {
      const { message } = await DeleteCategory(idToDelete);
      toast.success(message ?? 'Category successfully deleted');
      History.push({ pathname: `/categories/` });
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message ?? 'An error has occurred');
    }
    toast.dismiss(ToastID);

    setIdToDelete(null);
    setIsModalOpen(false);
  };

  const handleAssetDelete = async (AssetID: string) => {
    try {
      const res = await ToggleAssetFromCategory(ID, {
        action: 'remove',
        assetId: AssetID
      });
      toast.success(res?.message ?? 'Asset removed correctly');
      await refetchCategoryData();
    } catch (error) {
      toast.error(e?.message ?? 'An error has occurred');
    }
  };

  const handleAssetAdd = async (AssetID: string) => {
    try {
      const res = await ToggleAssetFromCategory(ID, {
        action: 'add',
        assetId: AssetID
      });
      toast.success(res?.message ?? 'Asset added correctly');
      await refetchCategoryData();
    } catch (error) {
      toast.error(e?.message ?? 'An error has occurred');
    }
    setIsAddAssetOpen(false);
  };

  const TableRow = (props: any) => {
    const { _id, name, description } = props.data;

    return (
      <Tr>
        <Td>
          <strong>{name}</strong>
        </Td>
        <Td>
          <div
            dangerouslySetInnerHTML={{
              __html: description
            }}
          ></div>
        </Td>
        <Td>
          <div
            style={{
              textAlign: 'center'
            }}
            onClick={() => {
              handleAssetDelete(_id);
            }}
          >
            <span
              className="mdi mdi-delete-outline"
              style={{
                fontSize: '1.5em',
                cursor: 'pointer'
              }}
            />
          </div>
        </Td>
      </Tr>
    );
  };

  console.log(assetsData?.assets)
  // console.log(categoryData)

  const RenderTableBody = () => {
    if (!assetsLoading && !categoryLoading && categoryData?.data?.assets?.length > 0) {
      return (
        <Tbody>
          {categoryData?.data?.assets
            .map((row: any) => {
              return <TableRow key={row._id} data={row} />;
            })}
        </Tbody>
      );
    } else if (
      !assetsLoading &&
      !categoryLoading &&
      categoryData?.data?.assets.length <= 0
    ) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="3">
              <Icon name="search" />
              No asset found
            </Td>
          </Tr>
        </Tbody>
      );
    } else if (!assetsLoading && !!assetsError) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="3">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <DeleteModal
            show={isModalOpen}
            onCloseClick={() => setIsModalOpen(false)}
            onDeleteClick={() => handleCategoryDelete()}
          />
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Categories" breadcrumbItem="Edit" />

          <Row>
            <Col xl={12}>
              {(categoryLoading || assetsLoading) && (
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              )}
              {!categoryLoading && !assetsLoading && (
                <React.Fragment>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col>
                            <h4 className="card-title">
                              Edit category <span>"{categoryData.data?.name}"</span>
                            </h4>
                            <p className="card-title-desc">
                              All fields are required
                            </p>
                          </Col>
                          <Col>
                            <div style={{ textAlign: 'right' }}>
                              <Button
                                style={{ marginRight: '10px' }}
                                color="warning"
                                onClick={() => {
                                  setIdToDelete(ID);
                                  setIsModalOpen(true);
                                }}
                              >
                                Delete this category
                              </Button>
                              <Button
                                disabled={validation.isSubmitting}
                                color="primary"
                                type="submit"
                              >
                                Save changes
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ''}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="py-3">
                            <Label htmlFor="validationCustom02">Image</Label>
                            <FormGroup className="mb-3">
                              <ImageUpload
                                setFieldValue={validation.setFieldValue}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="py-3">
                            <Label htmlFor="validationCustom02">
                              Description
                            </Label>
                            {editorLoading && (
                              <Card
                                style={{
                                  display: 'grid',
                                  placeContent: 'center',
                                  padding: '10px 0'
                                }}
                              >
                                <Spinner color="primary"></Spinner>
                              </Card>
                            )}
                            <Editor
                              id="validationCustom02"
                              initialValue={categoryData.data?.description}
                              apiKey={process.env.REACT_APP_API_KEY_TINY}
                              onInit={(evt, editor) => {
                                // @ts-expect-error
                                editorRef.current = editor;
                                setEditorLoading(false);
                              }}
                              init={{
                                height: 300,
                                menubar: 'edit format',
                                statusbar: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                  'undo redo | formatselect | ' +
                                  'bold italic backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | hr',
                                content_style:
                                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Form>

                  <Card>
                    <AddAsset
                      show={isAddAssetOpen}
                      assetsList={assetsData?.assets}
                      onCloseClick={() => setIsAddAssetOpen(!isAddAssetOpen)}
                      onSaveClick={(id) => handleAssetAdd(id)}
                    />
                    <CardHeader>
                      <Row>
                        <Col>
                          <h4 className="card-title">
                            Add/Remove assets from{' '}
                            <span>"{categoryData.data.name}"</span>
                          </h4>
                        </Col>
                        <Col>
                          <div style={{ textAlign: 'right' }}>
                            <Button
                              color="primary"
                              onClick={() => setIsAddAssetOpen(!isAddAssetOpen)}
                            >
                              Add
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered"
                              >
                                <Thead>
                                  <Tr>
                                    <Th
                                      style={{ textAlign: 'center' }}
                                      data-priority="1"
                                    >
                                      Name
                                    </Th>
                                    <Th
                                      style={{ textAlign: 'center' }}
                                      data-priority="1"
                                    >
                                      Description
                                    </Th>

                                    <Th
                                      style={{ textAlign: 'center' }}
                                      data-priority="1"
                                    >
                                      Actions
                                    </Th>
                                  </Tr>
                                </Thead>
                                <RenderTableBody />
                              </Table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCategory;
