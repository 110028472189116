import { get, post, patch, del, postMultiPart, put, putMultiPart } from '../helpers/api_helper';

// IMMEDIATE CALL GET ENDPOINTS
export const GET_ASSET = '/asset/';
export const GET_ALL_ASSETS = '/asset/all';
export const GET_ALL_TRANSACTIONS = '/admin/transaction/all';

export const GET_CATEGORY = '/category/';
export const GET_ALL_CATEGORIES = '/category/all';

export const GET_USERS = '/admin/user/all';

export const GET_ALL_SUBACCOUNTS = '/admin/asset/get-linked';

// USER

export const UserLogin = (data: any) => post('/user/login', data);
export const BlockUser = (ID: string, isDisabled: any) =>
  put(`/admin/user/${ID}`, isDisabled);

export const SendOtp = () =>
  get('/user/send-otp');
export const VerifyOTP = (otp:any) =>
  post('/user/verify-otp', {otp});

// ASSET

export const CreateNewAsset = (formData: any) =>
  postMultiPart('/admin/asset/create', formData);

export const UploadSignDocuments = (ID: string, formData: any) =>
  postMultiPart(`/admin/asset/${ID}/add-document`, formData);

export const EditAsset = (ID: string, formData: any) =>
  putMultiPart(`/admin/asset/${ID}/modify`, formData);

export const EditStateImage = (ID: string, formData: any) =>
  postMultiPart(`/admin/asset/${ID}/upload-state-image`, formData);

export const DeleteAsset = (ID: string | null) =>
  del(`/admin/asset/${ID}`);

export const EditPriceAsset = (ID: string, data: any) =>
  patch(`/admin/asset/${ID}/change-price`, data);

export const StatusUpdate = () => get(`/admin/asset/check-asset-status`);

export const MakeCashOut = (pix: any, code: any, amount: any) =>
  post('/admin/transaction/make-transfer', {pix, code, amount});

// CATEGORY

export const CreateNewCategory = (formData: any) =>
  postMultiPart('/admin/category/add', formData);

export const EditCategory = (ID: string, formData: any) =>
  putMultiPart(`/admin/category/${ID}/modify`, formData);

export const ToggleAssetCategory = (ID: string, data: any) =>
  patch(`/admin/category/${ID}/toggle-asset`, data);

export const DeleteCategory = (ID: string | null) =>
  del(`/admin/category/${ID}`);

export const ToggleAssetFromCategory = (categoryID: string, data: any) =>
  patch(`/admin/category/${categoryID}/toggle-asset`, data);

// MULTISIG

export const completeBuyAsset = (data: any) =>
  post(`/admin/asset/complete-buy-request`, data);

export const getUserDetails = (id: any) =>
  get(`/admin/user/${id}`);

export const getSingleSubaccount = (code: any) =>
  post(`/admin/asset/get-single-linked`, { code });