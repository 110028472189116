// @ts-nocheck
import React, { useState, useEffect } from 'react';
import TokenContract from './contracts/TokenContract.json';
import FactoryContract from './contracts/FactoryContract.json';
import GetWeb3 from './GetWeb3';

import Icon from '@ailibs/feather-react-ts/dist/Icon';

import CreateRequestModal from './components/CreateRequestModal';

import BlockchainContext from './BlockchainContext';

import AllRequests from './components/AllRequests';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  Container,
  Card,
  CardHeader,
  Col,
  Row,
  Spinner,
  CardBody,
  Button
} from 'reactstrap';
import { toast } from 'react-hot-toast';

function Multisig() {
  const [web3, setWeb3] = useState(undefined);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState([]);
  const [factoryContract, setFactoryContract] = useState([]);

  const [owner, setOwner] = useState(undefined);
  const [isSigner, setIsSigner] = useState(undefined);
  const [createModal, setCreateModal] = useState(false);

  const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        const web3 = await GetWeb3();

        const accounts = await web3.eth.getAccounts();

        const networkId = await web3.eth.net.getId();
        // const chainId = await web3.eth.getChainId();

        const deployedNetwork = TokenContract.networks[networkId];
        const contract = new web3.eth.Contract(
          TokenContract.abi,
          deployedNetwork && deployedNetwork.address
        );
        setWeb3(web3);
        setAccounts(accounts);
        setContract(contract);

        const deployedFactoryNetwork = FactoryContract.networks[networkId];
        const factoryContractObj = new web3.eth.Contract(
          FactoryContract.abi,
          deployedFactoryNetwork && deployedFactoryNetwork.address
        );

        setFactoryContract(factoryContractObj);

        // Verify Owner
        const ownerAddress = await contract.methods.admin().call();
        setOwner(ownerAddress);

        // verify signer
        const signer = await contract.methods.isSigner(accounts[0]).call();
        setIsSigner(signer);
      } catch (e) {
        setWeb3(null);

        if (e?.code === -32002) {
          toast.error(
            'Please open metamask manually and login into your wallet.\n\n' +
              e.message
          );
        } else {
          toast.error('An error has occurred.\n\n' + e.message);
        }
      }
    };

    init();
  }, []);

  const handleSuccess = () => {
    setCreateModal(false);
    setRefreshTrigger(!refreshTrigger);
  };

  if (typeof web3 === 'undefined') {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Multisig" />

            <Row>
              <Col xl={12}>
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <BlockchainContext.Provider
        value={{ web3, accounts, contract, owner, factoryContract }}
      >
        <CreateRequestModal
          show={createModal}
          onCloseClick={() => setCreateModal(!createModal)}
          onSuccess={() => handleSuccess()}
        />

        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Multisig" />

            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        {' '}
                        <h4 className="card-title">All Multisig requests</h4>
                        <p className="card-title-desc">
                          Here you can manage all requests, and create a new
                          one.
                        </p>
                      </Col>
                      <Col>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            disabled={!isSigner}
                            color="primary"
                            onClick={() => setCreateModal(!createModal)}
                          >
                            <Icon size={16} name="plus" />
                            <span className="mx-1">Create</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <AllRequests refreshTrigger={refreshTrigger} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </BlockchainContext.Provider>
    </React.Fragment>
  );
}

export default Multisig;
