import { call, put, takeEvery } from 'redux-saga/effects';

import {
  setAxiosInstanceAuth,
  removeAxiosInstanceAuth
} from 'src/helpers/api_helper';

import toast from 'react-hot-toast';

// Login Redux States
import { LoginTypes } from './actionTypes';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';

import { UserLogin } from 'src/services/api';

function* loginUser({ payload: { user, history } }: any) {
  const ToastID = toast.loading('Please wait...');
  try {
    const response: Promise<any> = yield call(UserLogin, {
      email: user.email,
      password: user.password
    });
    localStorage.setItem('authUser', JSON.stringify(response));
    yield put(loginSuccess(response));

    //@ts-ignore
    setAxiosInstanceAuth(response.data.token);

    history.push('/profile');
    toast.success('Welcome!');
  } catch (e: any) {
    /* yield put(apiError(e)); */
    toast.error(e?.response?.data?.message ?? 'An error has occurred');
  }

  toast.dismiss(ToastID);
}

function* logoutUser({ payload: { history } }: any) {
  try {
    localStorage.removeItem('authUser');
    removeAxiosInstanceAuth();

    history.push('/login');
    toast.success('Logged out!');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
}

export default authSaga;
