import React, {PureComponent} from 'react';

import useAxios from 'axios-hooks';

import { useState, useEffect, } from 'react';

import { GET_ALL_TRANSACTIONS } from 'src/services/api';

import { useSelector, useDispatch } from 'react-redux';

import { Route, useHistory } from 'react-router-dom';

import Breadcrumb from 'src/components/Common/Breadcrumb';

import Icon from '@ailibs/feather-react-ts/dist/Icon';

import { PieChart, Pie, Sector, LineChart, Line, XAxis, CartesianGrid, YAxis, Tooltip } from 'recharts';
import { BarChart, Bar, Legend } from 'recharts';
import { ScatterChart, Scatter } from 'recharts';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';
import { curveCardinal } from 'd3-shape';

import { toast } from 'react-hot-toast';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Button
} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import {get} from '../../helpers/api_helper';

//import Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container } from 'reactstrap';
import { GET_USER_PROFILE } from 'src/helpers/url_helper';

const Dashboard = () => {
  document.title = 'All Transactions | Goyield Backoffice';

  const LineChartCustom = () => {
    const [{ data, loading, error }, refetch] = useAxios(GET_ALL_TRANSACTIONS);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    const transaction = data?.transactions.map((row: any) => {
      return {
        name: row._id,
        value: row.value,
      };
    });
  
    return (
      <div className="d-flex justify-content-center">
        <div className="d-flex d-lg-none">
          <LineChart width={300} height={350} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} data={transaction}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="value" stroke="#FF5A5F" />
          </LineChart>
        </div>
        <div className="d-none d-lg-flex">
          <LineChart width={550} height={350} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} data={transaction}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="value" stroke="#FF5A5F" />
          </LineChart>
        </div>
      </div>
    );
  };

  const PieChartCustom = () => {
    const [{ data, loading, error }, refetch] = useAxios(GET_ALL_TRANSACTIONS);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    const categoryData: Record<string, number> = {};

    data?.transactions?.forEach((row: any) => {
      if (!categoryData[row._id]) {
        categoryData[row._id] = 0;
      }
      categoryData[row._id] += row.value;
    });
  
    const transaction = Object.entries(categoryData).map(([_id, value]) => ({
      _id,
      value,
    }));
  
    return (
      <div className="d-flex justify-content-center">
        <div className="d-flex d-lg-none">
          <PieChart width={300} height={350}>
            <Pie data={transaction} dataKey="value" nameKey="_id" cx="50%" cy="50%" outerRadius={80} fill="#FF5A5F" label />
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
        <div className="d-none d-lg-flex">
          <PieChart width={500} height={350}>
            <Pie data={transaction} dataKey="value" nameKey="_id" cx="50%" cy="50%" outerRadius={80} fill="#FF5A5F" label />
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>
    );
  };

  const BarChartCustom = () => {
    const [{ data, loading, error }, refetch] = useAxios(GET_ALL_TRANSACTIONS);
    console.log(data)
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    const categoryData: Record<string, number> = {};

    data?.transactions?.forEach((row: any) => {
      if (!categoryData[row._id]) {
        categoryData[row._id] = 0;
      }
      categoryData[row._id] += row.value;
    });
  
      const chartData = Object.entries(categoryData).map(([_id, value]) => ({ _id, value }));
      
      return (
        <div className="d-flex justify-content-center">
          <div className="d-flex d-lg-none">
            <BarChart width={300} height={350} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="_id" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#FF5A5F" />
            </BarChart>
          </div>
          <div className="d-none d-lg-flex">
            <BarChart width={550} height={350} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="_id" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#FF5A5F" />
            </BarChart>
          </div>
        </div>
      );
  };

  const [page, setPage] = useState(1);
  const num = 5;
  const [count, setCount] = useState(0)

  const [{ data, loading, error }, refetch] = useAxios({
    url: GET_ALL_TRANSACTIONS,
    params: {
      page,
      num
    }
  });

  useEffect(() => {
    if(data?.count) {
      setCount(data.count);
    }
  }, [data]);

  const handleNextPage = () => {
    if (page < Math.ceil(data?.count / num)) {
      setPage((prevPage) => prevPage + 1); // aumenta la pagina di 1
      refetch();
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1); // diminuisce la pagina di 1 solo se la pagina è maggiore di 1
      refetch();
    }
  };

  /* console.log(data?.transactions)
  console.log(data) */

  const RenderTableBody = () => {
    if (!loading && !!data) {
      const filteredData = data?.transactions.filter((row: any) => !row.deleted);
      const page = data?.page;
      console.log(page)
      return (
        <Tbody>
          {filteredData.map((row: any) => {
            return <TableRow key={row._id} data={row} />;
          })}
        </Tbody>
      );
    } else if (!loading && !!error) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="8">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    } else {
      return null;
    }
  };

  const TableRow = (props: any) => {
    const {
      _id,
      type,
      status,
      user,
      asset,
      amount,
      value,
      createdAt
    } = props.data;

    const creationDate = new Date(createdAt);
    const formattedDate = creationDate.toLocaleDateString();
    const formattedTime = creationDate.toLocaleTimeString();

    const history = useHistory();

    return (
      <Tr>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{_id}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{type === 'send_token' ? 'Send token' : type === 'burn_token' ? 'Burn token' : 'No type'}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{status === 'reserved' ? 'Token reserved' : status === 'completed' ? 'Token bought' : 'No status'}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{user}</strong>
        </Td>
        <td>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <span>
              <strong>{asset}</strong>
            </span>
          </span>
        </td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{amount}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{value}</strong>
        </Td>
        <td>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <span>
              <strong>{formattedDate} {formattedTime}</strong>
            </span>
          </span>
        </td>
      </Tr>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Dashboard" />
          <div className="insights_info">
            <h2 className='py-3'>
              Transactions insights
            </h2>
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-6 value_chart">
                <div className='card shadow p-3 value_insight d-flex justify-content-center mx-auto'>
                  <LineChartCustom />
                </div>
              </div>
              {/* <div className="col-12 col-md-6 value_chart mr-2">
                <div className='card shadow p-3 value_insight d-flex justify-content-center'>
                  <PieChartCustom />
                </div>
              </div> */}
              <div className="col-12 col-md-12 col-lg-12 col-xl-6 value_chart mr-2">
                <div className='card shadow p-3 value_insight d-flex justify-content-center'>
                  <BarChartCustom />
                </div>
              </div>
            </div>
          </div>
          <div className='transactions_info'>
            <h2 className='py-3'>
              Last transactions
            </h2>
            {loading && (
              <Card
                style={{
                  display: 'grid',
                  placeContent: 'center',
                  padding: '10px 0'
                }}
              >
                <Spinner color="primary"></Spinner>
              </Card>
            )}
            {!loading && (
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="1"
                        >
                          Transaction Id
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="1"
                        >
                          Type
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="6"
                        >
                          Status
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="6"
                        >
                          User Id
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="6"
                        >
                          Asset Id
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="3"
                        >
                          Amount
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="3"
                        >
                          Invested
                        </Th>
                        <Th
                          style={{ textAlign: 'center' }}
                          data-priority="3"
                        >
                          Created/Updated
                        </Th>
                      </Tr>
                    </Thead>

                    <RenderTableBody />
                  </Table>
                  <div className='pagination mt-5 d-flex justify-content-center align-items-center'>
                    <button className='border-0 bg-transparent p-2' onClick={handlePrevPage}>ᐸ</button>
                    <h5 className='mx-2 my-0'>Results: {count}</h5>
                    <h5 className='mx-2 my-0'>Page: {page}</h5>
                    <button className='border-0 bg-transparent p-2' onClick={handleNextPage}>ᐳ</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

interface Transactions {
  _id: string;
  type: string;
  status:string;
  amount: number;
  updatedAt: Date;
}

export default Dashboard;
