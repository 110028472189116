import React from 'react';

import { useHistory } from 'react-router-dom';

import { Row, Col, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import useAxios from 'axios-hooks';

import { GET_ALL_CATEGORIES } from 'src/services/api';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//Import Breadcrumb
import Breadcrumb from 'src/components/Common/Breadcrumb';

const AllCategories = () => {
  document.title = 'All Categories | Goyield Backoffice';

  const History = useHistory();

  const [{ data, loading, error }, refetch] = useAxios(GET_ALL_CATEGORIES);

  const handleEdit = (ID: string) => {
    History.push({
      pathname: `/categories/${ID}`
    });
  };

  const TableRow = (props: any) => {
    const { _id, name, description, assets } = props.data;

    console.log(assets)

    return (
      <Tr style={{ cursor: 'pointer' }} onClick={() => handleEdit(_id)}>
        <Td>
          <strong>{name}</strong>
        </Td>
        <Td>
          <div
            dangerouslySetInnerHTML={{
              __html: description
            }}
          ></div>
        </Td>
        <Td style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {assets ?? 0}
        </Td>
      </Tr>
    );
  };

  const RenderTableBody = () => {
    console.log(data)
    if (!loading && !!data) {
      return (
        <Tbody>
          {data.data.map((row: any) => {
            return <TableRow key={row._id} data={row} />;
          })}
        </Tbody>
      );
    } else if (!loading && !!error) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="4">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Categories" />

          <Row>
            <Col>
              {loading && (
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              )}
              {!loading && (
                <Card>
                  <CardHeader>
                    <h4 className="card-title">All categories</h4>
                    <p className="card-title-desc">
                      Click on a row to edit the selected category
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Name
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Description
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Assets
                              </Th>
                            </Tr>
                          </Thead>

                        {/* {JSON.stringify(data)} */}

                          <RenderTableBody />
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllCategories;
