import React, { useState, useEffect, useContext } from 'react';
import BlockchainContext from '../BlockchainContext';
import SingleRequest from './SingleRequest';

import { Col, Row, Spinner } from 'reactstrap';

const AllRequests = ({ refreshTrigger }) => {
  const Context = useContext(BlockchainContext);
  const { accounts, contract } = Context;
  const [reqs, setReqs] = useState(undefined);
  const [signers, setSigners] = useState(undefined);

  useEffect(() => {
    const fetchRequests = async () => {
      if (contract.methods) {
        const signersCount = await contract.methods.getSignersCount().call();
        setSigners(signersCount);
        const requestsNumber = await contract.methods
          .getRequestsNumber()
          .call();
        let requests = [];
        for (let i = 0; i < requestsNumber; i++) {
          const response = await contract.methods.getRequest(i).call();
          const alreadyVoted = await contract.methods
            .getApprovals(i, accounts[0])
            .call();
          requests.push({
            amount: response.amount,
            beneficiary: response.beneficiary,
            createdBy: response.createdBy,
            timestamp: response.timestamp,
            votesCount: response.votesCount,
            status: response.status,
            alreadyVoted
          });
        }
        setReqs(requests);
      }
    };
    fetchRequests();
  }, [contract, refreshTrigger]);

  const RenderRequests = () => {
    if (!reqs) {
      return (
        <React.Fragment>
          <span
            style={{
              display: 'grid',
              placeContent: 'center',
              padding: '10px 0'
            }}
          >
            <Spinner color="primary"></Spinner>
          </span>
        </React.Fragment>
      );
    } else if (!!reqs && reqs.length === 0) {
      return (
        <React.Fragment>
          <span className="m-4">No data</span>
        </React.Fragment>
      );
    } else if (!!reqs && reqs.length !== 0) {
      return reqs.map((request, id) => (
        <React.Fragment key={id}>
          <span className="m-4">
            <SingleRequest
              reqId={id}
              beneficiary={request.beneficiary}
              amount={request.amount}
              timestamp={request.timestamp}
              createdBy={request.createdBy}
              votesCount={request.votesCount}
              status={request.status}
              signers={signers}
              alreadyVoted={request.alreadyVoted}
            />
          </span>
          {reqs.length > 1 ? <hr /> : null}
        </React.Fragment>
      ));
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <RenderRequests />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AllRequests;
