import PropTypes from 'prop-types';
import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';

const DeleteModal = ({ show, onDeleteClick, onCloseClick }: any) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: '7em' }}
              />
              <h3>Are you sure?</h3>
              <h6 style={{ fontWeight: 'lighter' }}>
                {"You won't be able to revert this!"}
              </h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={onDeleteClick}
              >
                Delete
              </button>
              <button type="button" className="btn ms-2" onClick={onCloseClick}>
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
};

export default DeleteModal;
