import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap';
import { BlockUser, MakeCashOut, getUserDetails } from 'src/services/api';
import { FaUserCircle, FaBirthdayCake, FaMapSigns, FaInfo, FaUser, FaVoicemail, FaMailBulk, FaClock, FaMap, FaIdCard, FaDrawPolygon, FaEye } from "react-icons/fa";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//Import Breadcrumb
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { toast } from 'react-hot-toast';
import { Field } from 'formik';
import CashoutModal from 'src/components/Common/CashoutModal';

const SingleUser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [amount, setAmount] = useState('')
  const [formattedAmount, setFormattedAmount] = useState('');
  const [balance, setBalance] = useState('0,00')

  document.title = 'User Details | Goyield Backoffice';

  const { id } = useParams<{ id: string }>();
  console.log(id);

  interface UserData {
    user: {
      _id: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      address: {
        city: string;
        country: string;
      };
      birth: {
        date: string;
        city: string;
      };
      cf: string;
      polygonAddress: string;
      isActive: boolean;
      role: string;
    }
  }
  
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userBlocked, setUserBlocked] = useState(false)

  useEffect(() => {
    async function getData() {
      try {
        const response = await getUserDetails(id);
        setUserData(response);
        console.log(response)
        setUserBlocked(response?.isDisabled)
      } catch (error) {
        console.error(error);
      }
    }

    getData();
  }, [id]);

  async function blockUser() {
    console.log('block')
    try {
      const response = await BlockUser(id, {isDisabled: true});

      if (response) {
        console.log(response)
        setUserBlocked(true)
        toast.success('User blocked.');
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function unlockUser() {
    console.log('unlock')
    try {
      const response = await BlockUser(id, {isDisabled: false});

      if (response) {
        console.log(response)
        setUserBlocked(false)
        toast.success('User unlocked.');
      }

    } catch (error) {
      console.error(error);
    }
  }

  console.log(userData?.user);

  const birthDate = new Date(userData?.user?.birth.date ?? "");
  const formattedBirthDate = birthDate.toLocaleDateString();

  const creationDate = new Date(userData?.user?.createdAt ?? "");
  const formattedCreationDate = creationDate.toLocaleDateString();

  const handleSendMoney = async () => {
    try {
      // Sostituisci tutte le occorrenze di virgola con il punto in `amount`
      const formattedAmount = amount.replace(/,/g, '.');
  
      console.log(formattedAmount, userData?.user?._id);
      // Utilizza `formattedAmount` al posto di `amount` per la chiamata alla funzione
      // const response = await MakeCashOut(pix, userData?.user?._id, formattedAmount);
  
      toast.success('Funds sent to the User.');
      setIsModalOpen(false);
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
    }
  }

  useEffect(() => {
    // Rimuovi prima eventuali formattazioni precedenti per evitare errori di conversione
    const numericAmount = Number(amount.replace(/,/g, '.').replace(/[^\d.-]/g, ''));
    if (!isNaN(numericAmount)) {
      // Usa `Intl.NumberFormat` per formattare il numero con punti per migliaia e virgola per i decimali
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'decimal',
        maximumFractionDigits: 2,
      });
      setFormattedAmount(formatter.format(numericAmount));
    }
  }, [amount]);

  const userInfo = [
    { icon: FaUser, label: "Name", value: `${userData?.user?.firstName} ${userData?.user?.lastName}` },
    { icon: FaBirthdayCake, label: "Birth Date", value: formattedBirthDate },
    { icon: FaMapSigns, label: "Birth Country", value: userData?.user?.birth?.city },
    { icon: FaMailBulk, label: "Email", value: userData?.user?.email },
    { icon: FaClock, label: "Creation Date", value: formattedCreationDate },
    { icon: FaMap, label: "Address", value: `${userData?.user?.address?.city}, ${userData?.user?.address?.country}` },
    { icon: FaIdCard, label: "Fiscal Code", value: userData?.user?.cf },
    { icon: FaDrawPolygon, label: "Polygon", value: userData?.user?.polygonAddress || 'No Polygon.' },
    { icon: FaEye, label: "Active", value: userData?.user?.isActive ? 'Yes' : 'No' },
    { icon: FaUser, label: "Role", value: userData?.user?.role },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem={"User ID: " + userData?.user?._id} />
          {!userData?.user && <h3>Loading...</h3>}
          {userData?.user && 
            <div className="user_informations">
                <div className='action_buttons'>
                    {userBlocked ? (
                    <Button
                        color="warning"
                        onClick={() => unlockUser()}
                        className='mb-4'
                      >
                        Unlock user
                    </Button>
                    ) : (
                    <Button
                      color="danger"
                      onClick={() => blockUser()}
                      className='mb-4'
                    >
                      Block user
                    </Button>
                    )
                    }
                </div>
                <div className="row">
                  {userInfo.map((info, index) => (
                    <div key={index} className="col-12 col-md-6 d-flex flex-column flex-lg-row align-items-start align-items-lg-center py-2">
                      <info.icon color="#F43E5E"/>
                      <h4 className='px-2 pt-0 pb-0'>{info.label}: <span style={{fontSize:15}} className='text-muted'>{info.value}</span></h4>
                    </div>
                  ))}
                  {/* <div className="d-flex flex-column align-items-start py-2">
                    <h3>Make a CashOut to <span style={{ color:'#F43E5E' }}>{userData?.user?.firstName} {userData?.user?.lastName}</span></h3>
                    <div className='d-flex align-items-end flex-wrap gap-4'>
                      <div className='mt-2'>
                        <h5>User's balance: R$ <span>{balance}</span></h5>
                        {formattedAmount && <h5>Amount to Send: R$ {formattedAmount}</h5>}
                        <Input
                          type="number"
                          placeholder="Insert an amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                      <Button disabled={!amount} onClick={() => setIsModalOpen(true)} color='primary'>Send</Button>
                    </div>
                  </div>
                  <CashoutModal
                    show={isModalOpen}
                    amount={formattedAmount}
                    onCloseClick={() => setIsModalOpen(false)}
                    onCashoutClick={() => handleSendMoney()}
                  /> */}
                </div>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleUser;
