import React, { useContext } from 'react';
import BlockchainContext from '../BlockchainContext';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  ModalHeader
} from 'reactstrap';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

const CreateRequestModal = ({ show, onCloseClick, onSuccess }) => {
  document.title = 'Multisig | Goyield Backoffice';

  const Context = useContext(BlockchainContext);
  const { accounts, contract } = Context;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      amount: '',
      beneficiary: ''
    },
    validationSchema: Yup.object({
      amount: Yup.number().required('This field is required'),
      beneficiary: Yup.string().required('This field is required')
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = async ({ amount, beneficiary }) => {
    const parsedAmount = amount * 10 ** 18;

    const ToastID = toast.loading(
      'Please continue on the notification window... This might take a while to process!'
    );

    try {
      const res = await contract.methods
        .createRequest(
          parsedAmount.toLocaleString('fullwide', { useGrouping: false }),
          beneficiary
        )
        .send({
          from: accounts[0]
        });
      toast.success(res?.message ?? 'Request approved successfully');
      onSuccess();
    } catch (e) {
      toast.error(e?.message ?? 'An error has occurred');
    }
    toast.dismiss(ToastID);
  };
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>Create a new request</ModalHeader>
      <ModalBody className="py-3 px-5">
        <Form
          className="custom-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label className="form-label">Token amount</Label>
                <Input
                  name="amount"
                  type="number"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.amount || ''}
                  invalid={
                    validation.touched.amount && validation.errors.amount
                      ? true
                      : false
                  }
                />
                {validation.touched.amount && validation.errors.amount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.amount}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Beneficiary address </Label>
                <Input
                  name="beneficiary"
                  value={validation.values.beneficiary || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.beneficiary &&
                    validation.errors.beneficiary
                      ? true
                      : false
                  }
                />
                {validation.touched.beneficiary &&
                validation.errors.beneficiary ? (
                  <FormFeedback type="invalid">
                    {validation.errors.beneficiary}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="submit"
                  value="Create"
                  className="btn btn-primary ms-2"
                >
                  Create
                </button>
                <button
                  type="button"
                  className="btn ms-2"
                  onClick={onCloseClick}
                >
                  Close
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

CreateRequestModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any
};

export default CreateRequestModal;
