import React from 'react';
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  ModalHeader
} from 'reactstrap';

// Formik validation
import { useFormik } from 'formik';

interface AddAssetProps {
  show: boolean;
  assetsList: any;
  onCloseClick: () => void;
  onSaveClick: (id: string) => void;
}

const AddAsset = ({
  show,
  assetsList,
  onCloseClick,
  onSaveClick
}: AddAssetProps) => {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      asset: ''
    },

    onSubmit: ({ asset }) => onSaveClick(asset)
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>Add asset to category</ModalHeader>
      <ModalBody className="py-3 px-5">
        <Form
          className="custom-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label className="form-label">Asset</Label>
                <Input
                  name="asset"
                  type="select"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.asset || ''}
                  invalid={
                    validation.touched.asset && validation.errors.asset
                      ? true
                      : false
                  }
                >
                  <option selected>Choose...</option>
                  {assetsList.map((e: any) => (
                    <option key={e._id} value={e._id}>
                      {e.name}
                    </option>
                  ))}
                </Input>
                {validation.touched.asset && validation.errors.asset ? (
                  <FormFeedback type="invalid">
                    {validation.errors.asset}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="submit"
                  value="Create"
                  className="btn btn-primary ms-2"
                  disabled={!validation.values.asset}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn ms-2"
                  onClick={onCloseClick}
                >
                  Close
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddAsset;
