import React, { useState } from 'react';
import { Row, Col, Card, Form, Label } from 'reactstrap';
import Dropzone from 'react-dropzone';

import { Link } from 'react-router-dom';

const ImageUpload = ({ setFieldValue }: any) => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );
    setSelectedFiles(files);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <React.Fragment>
      {selectedFiles.length !== 1 && (
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleAcceptedFiles(acceptedFiles);
            setFieldValue('image', acceptedFiles);
          }}
          accept="image/*"
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick mt-2" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bx-cloud-upload" />
                </div>
                <h4>Drop an image here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f: any, i: number) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + '-file'}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <span
                      style={{ cursor: 'pointer' }}
                      className="px-3"
                      onClick={() => setSelectedFiles([])}
                    >
                      X
                    </span>
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ImageUpload;
