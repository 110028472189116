import React from 'react';

import { Route, useHistory } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import { useState, useEffect, } from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Button
} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import Icon from '@ailibs/feather-react-ts/dist/Icon';

import useAxios from 'axios-hooks';

import {GET_ALL_TRANSACTIONS} from 'src/services/api';

import { PieChart, Pie, Sector, LineChart, Line, XAxis, CartesianGrid, YAxis, Tooltip } from 'recharts';
import { BarChart, Bar, Legend } from 'recharts';

import { GET_USERS } from 'src/services/api';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//Import Breadcrumb
import Breadcrumb from 'src/components/Common/Breadcrumb';
import SingleUser from '../Single/Index';

const AllUsers = () => {
  document.title = 'All Users | Goyield Backoffice';

  const [page, setPage] = useState(1);
  const num = 5;
  const [count, setCount] = useState(0)

/*   const [{ data, loading, error }, refetch] = useAxios(GET_USERS); */
  const [{ data, loading, error }, refetch] = useAxios({
    url: GET_USERS,
    params: {
      page,
      num
    }
  });

  useEffect(() => {
    if(data?.count) {
      setCount(data.count);
    }
  }, [data]);

  const PieChartCustom = () => {
    const [{ data, loading, error }, refetch] = useAxios(GET_USERS);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    const countryData: Record<string, number> = {};
  
    data?.users?.forEach((user: any) => {
      const country = user?.address?.country;
      if (!countryData[country]) {
        countryData[country] = 0;
      }
      countryData[country]++;
    });
  
    const users = Object.entries(countryData).map(([country, count]) => ({
      country,
      count,
    }));
  
    return (
      <div className="d-flex justify-content-center">
        <div className="d-flex d-lg-none">
          <PieChart width={300} height={350}>
            <Pie data={users} dataKey="count" nameKey="country" cx="50%" cy="50%" outerRadius={80} fill="#FF5A5F" label />
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
        <div className="d-none d-lg-flex">
          <PieChart width={500} height={350}>
            <Pie data={users} dataKey="count" nameKey="country" cx="50%" cy="50%" outerRadius={80} fill="#FF5A5F" label />
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>
    );
  };

  const handleNextPage = () => {
    if (page < Math.ceil(data?.count / num)) {
      setPage((prevPage) => prevPage + 1); // aumenta la pagina di 1
      refetch();
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1); // diminuisce la pagina di 1 solo se la pagina è maggiore di 1
      refetch();
    }
  };
  
  const RenderTableBody = () => {
    if (!loading && !!data) {
      const filteredData = data?.users?.filter((row: any) => !row.deleted);
      return (
        <Tbody>
          {filteredData.map((row: any) => {
            return <TableRow key={row._id} data={row} />;
          })}
        </Tbody>
      );
    } else if (!loading && !!error) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="8">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    } else {
      return null;
    }
  };

  const TableRow = (props: any) => {
    const {
      _id,
      firstName,
      lastName,
      email,
      role,
      isActive,
      createdAt,
      polygonAddress,
      birth,
      cf,
      address
    } = props.data;

    const creationDate = new Date(createdAt);
    const formattedDate = creationDate.toLocaleDateString();
    const formattedTime = creationDate.toLocaleTimeString();

    const birthDate = new Date(birth.date);
    const formattedBirthDate = birthDate.toLocaleDateString();

    const history = useHistory();

    return (
      <Tr style={{ cursor: 'pointer' }} onClick={() => history.push(`/users/${_id}`)}>
        <Td>
          <div className="text-center">
            {isActive ? (
              <Icon name="check" color="green" />
            ) : (
              <Icon name="x" color="red" />
            )}
          </div>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{firstName} {lastName}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{formattedBirthDate}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{cf}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{address.country}</strong>
        </Td>
        <td>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <span>
              <strong>{role.toUpperCase()}</strong>
            </span>
          </span>
        </td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{email}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{polygonAddress ? polygonAddress : 'No address.'}</strong>
        </Td>
        <td>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <span>
              <strong>{formattedDate} {formattedTime}</strong>
            </span>
          </span>
        </td>
      </Tr>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Users" />

          <Row>
            <Col>
              {loading && (
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              )}
              {!loading && (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h4 className="card-title">All users</h4>
                        <p className="card-title-desc">
                          Click on a row to edit the selected user
                        </p>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <div className="users_insights">
                      <h2 className=''>
                        User insights
                      </h2>
                      <div className="row justify-content-center pt-3">
                        <div className="col-12 col-md-4 value_chart">
                          <div className='card shadow p-3 value_insight d-flex justify-content-center mx-auto'>
                            <PieChartCustom />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 value_chart mr-2">
                          <div className='card shadow p-3 value_insight d-flex justify-content-center'>
                            <PieChartCustom />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 value_chart mr-2">
                          <div className='card shadow p-3 value_insight d-flex justify-content-center'>
                            <PieChartCustom />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Active
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Name
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="6"
                              >
                                Date of Birth
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="6"
                              >
                                Fiscal Code
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="6"
                              >
                                Country
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Role
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Email
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Polygon Address
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="6"
                              >
                                Created/Updated
                              </Th>
                            </Tr>
                          </Thead>
                          <RenderTableBody />
                        </Table>
                        <div className='pagination mt-5 d-flex justify-content-center align-items-center'>
                          <button className='border-0 bg-transparent p-2' onClick={handlePrevPage}>ᐸ</button>
                          <h5 className='mx-2 my-0'>Results: {count}</h5>
                          <h5 className='mx-2 my-0'>Page: {page}</h5>
                          <button className='border-0 bg-transparent p-2' onClick={handleNextPage}>ᐳ</button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllUsers;
