import React, { useEffect, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { Button, Input, Label } from "reactstrap";
import {
  BlockUser,
  MakeCashOut,
  SendOtp,
  VerifyOTP,
  getSingleSubaccount,
  getUserDetails,
} from "src/services/api";
import {
  FaUserCircle,
  FaBirthdayCake,
  FaMapSigns,
  FaInfo,
  FaUser,
  FaVoicemail,
  FaMailBulk,
  FaClock,
  FaMap,
  FaIdCard,
  FaDrawPolygon,
  FaEye,
} from "react-icons/fa";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumb from "src/components/Common/Breadcrumb";
import { toast } from "react-hot-toast";
import { Field } from "formik";
import CashoutModal from "src/components/Common/CashoutModal";
import { formatBalance } from "src/services/formatBalance";
import { useHistory } from 'react-router'

const SingleSubaccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  
  const fullPath = location.pathname + location.hash;

  const codeMatch = fullPath.match(/\/banking\/(.*)/);
  const code = codeMatch ? codeMatch[1] : "Unknown";

  console.log(code);
  document.title = `${code} Subaccount | Goyield Backoffice`;

  const [amount, setAmount] = useState("");
  const [pix, setPix] = useState("");
  const [formattedAmount, setFormattedAmount] = useState("");
  const [balance, setBalance] = useState("0,00");

  const [account, setAccount] = useState<{
    balance: number;
    blockedBalance: number;
    minimumBalance: number;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const response = await getSingleSubaccount(code);
        console.log(response)
        setAccount(response.data.data);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    getData();
  }, [code]);

  const history = useHistory()

  const handleSendMoney = async () => {
    try {
      // Sostituisci tutte le occorrenze di virgola con il punto in `amount`
      const formattedAmount = amount.replace(/,/g, ".");
      /*   
      console.log(formattedAmount, userData?.user?._id); */
      // Utilizza `formattedAmount` al posto di `amount` per la chiamata alla funzione
      const response = await MakeCashOut(pix, code, parseFloat(formattedAmount)/*  * 100 */);
      console.log(response.data.success)

      if (response.data.success) {
        toast.success("Funds sent to the User.");
        window.location.reload()
      } else {
        toast.error(response.data.message);
      }

      setIsModalOpen(false);
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    // Rimuovi prima eventuali formattazioni precedenti per evitare errori di conversione
    const numericAmount = Number(
      amount.replace(/,/g, ".").replace(/[^\d.-]/g, "")
    );
    if (!isNaN(numericAmount)) {
      // Usa `Intl.NumberFormat` per formattare il numero con punti per migliaia e virgola per i decimali
      const formatter = new Intl.NumberFormat("it-IT", {
        style: "decimal",
        maximumFractionDigits: 2,
      });
      setFormattedAmount(formatter.format(numericAmount));
    }
  }, [amount]);

  const sendUserOTP = async () => {
    try {
      const response = await SendOtp();
      console.log(response)
      
    } catch (err) {
      console.log(err);
    }
  };

  const handleModalOtp = async () => {
    sendUserOTP()
    setIsModalOpen(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem={"Account CODE: " + code} />
          {loading ? (
            <h3>Loading...</h3>
          ) : (
            <>
              <div className="account_informations">
                <h4>Balance: R$ {formatBalance(account?.balance)}</h4>
              </div>
              <h3 className="pt-5">Make a Bank Transfer</h3>
              <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 gap-lg-4 mt-4">
                <div>
                  {formattedAmount && (
                    <h5>Amount to Send: R$ {formattedAmount}</h5>
                  )}
                  <Input
                    type="number"
                    className="mb-2" // Margine in basso per spaziatura tra gli input
                    placeholder="Insert an amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  {formattedAmount && (
                    <h5>Pix that will receive funds: {pix}</h5>
                  )}
                  <Input
                    type="text"
                    className="mb-2" // Margine in basso se necessario spazio extra
                    placeholder="Insert a Pix Key"
                    value={pix}
                    onChange={(e) => setPix(e.target.value)}
                  />
                </div>
              </div>
              <Button
                  className="mt-2 mt-lg-0" // Margine top solo su dispositivi piccoli
                  disabled={!amount || !pix}
                  onClick={() => handleModalOtp()}
                  color="primary"
                >
                  Send
                </Button>

              <CashoutModal
                show={isModalOpen}
                amount={formattedAmount}
                onCloseClick={() => setIsModalOpen(false)}
                onCashoutClick={() => handleSendMoney()}
              />
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleSubaccount;
