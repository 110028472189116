// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';

import { Redirect, useHistory } from 'react-router-dom';

import ImageUpload from '../components/ImageUpload';

import useAxios from 'axios-hooks';

import DeleteModal from 'src/components/Common/DeleteModal';

import {
  GET_ASSET,
  GET_ALL_CATEGORIES,
  EditAsset as EditAssetApi,
  EditPriceAsset,
  DeleteAsset,
  UploadSignDocuments,
  EditStateImage
} from 'src/services/api';

import toast from 'react-hot-toast';

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  FormFeedback,
  Form
} from 'reactstrap';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import AssetPriceHistory from '../components/AssetPriceHistory';

const EditAsset = ({ match }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log("begin-1")
  const ID = match.params.id;
  const [{ data, loading, error }, refetch] = useAxios(`${GET_ASSET}${ID}`);
  const [{ data: categoryData, loading: categoryLoading, error: categoryError }, categoryRefetch] = useAxios(GET_ALL_CATEGORIES);

  const [token, setToken] = useState(true);
  const [texts, setTexts] = useState(true);
  const [estimatedYield, setEstimatedYield] = useState(true);

  const [editorLoading, setEditorLoading] = useState(true);
  const [documents, setDocuments] = useState([])
  const [currentPrice, setCurrentPrice] = useState(0);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);

  const [image, setImage] = useState(null);
  const [stateImage, setStateImage] = useState(null);

  const handleStateImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setStateImage(e.target.files[0]);
    } else {
      setStateImage(null);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    } else {
      setImage(null);
    }
  };

  const [categories, setCategories] = useState([])

  const [signDocuments, setSignDocuments] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // Nota: ho cambiato da 'file' a 'files'
    console.log(file);
    if (file) {
      setSignDocuments(file);
      uploadDocuments(ID, file);
    }
  };
  
  const uploadDocuments = async (id: string, document: File) => {
    let documentData = new FormData();
    console.log('document', document);
    if (document) {
      documentData.append('document', document);
      documentData.append('name', document.name);
      console.log(document);
    }
  
    try {
      const fileResponse = await UploadSignDocuments(id, documentData);
      if (fileResponse.success) {
        window.location.reload();
        console.log('Documenti caricati nell\'asset');
        toast.success(fileResponse.message ?? 'Documents uploaded successfully.');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while uploading the documents.');
    }
  };

  const editorRef = useRef(null);

  document.title = 'Edit Asset | Goyield Backoffice';

  useEffect(() => {
    if (loading || error) return;

    /* setCategories(categoryData.data.categories);
    console.log(categoryData) */
    setDocuments(data.data.documents)
    setToken(data.data.token);
    setTexts(data.data.text)
    setEstimatedYield(data.data.text.estimatedYield)
    setCurrentPrice(data.data.values?.chart.at(data.data.values?.chart?.length - 1)?.value);
  }, [loading]);

  const paymentPeriod = [
    'Acquisition',
    'Qualification and approval',
    'Agreement',
    'Payment',
    'Distribution'
  ]

  const riskLevel = [
    'mb',
    'b',
    'm',
    'ma',
    'a',
    'max'
  ]

  const handlePriceChange = async () => {
    const ToastID = toast.loading('Please wait...');

    try {
      const { message } = await EditPriceAsset(ID, { value: currentPrice });
      toast.success(message ?? 'Price edited successufully');
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'An error has occurred');
    }
    toast.dismiss(ToastID);
    await refetch();
  };

  const history = useHistory();

  const handleAssetDelete = async () => {
    const ToastID = toast.loading('Please wait...');

    try {
      const { message } = await DeleteAsset(ID, { softDelete: true });
      toast.success(message ?? 'Asset successfully deleted');
      history.push('/assets');
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message ?? 'An error has occurred');
      history.push('/assets');
    }
    toast.dismiss(ToastID);
    setIsModalOpen(false);
  };

  console.log('begin')
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      ...data?.data,
      issuingDate: !!data
        ? new Date(data.data.schedule?.issuingDate).toISOString().slice(0, 10)
        : null,
      distributionClosingDate: !!data ? new Date(data.data.schedule?.distributionClosingDate).toISOString().slice(0, 10) : null,
      dueDate: !!data ? new Date(data.data.schedule?.dueDate).toISOString().slice(0, 10) : null
    },

    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      subtitle: Yup.string().required('Required'),
      devedor: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      issuingDate: Yup.date(),
      distributionClosingDate: Yup.date(),
      value: Yup.number().moreThan(0, 'Required.')
    }),
    onSubmit: async (values) => {
      console.log(values)
      const { active, name, value, issuingDate, distributionClosingDate, visible, reserving, minInvestment } = values;
      const ToastID = toast.loading('Please wait...');

      let description = '';
      if (editorRef.current) {
        description = editorRef.current.getContent();
      }

      try {
        let formData = new FormData();
        let newFormData = new FormData();

        for (const property in values) {
          //@ts-expect-error
          if (typeof values[property] === 'object' && values[property] !== null) {
            
            formData.append(property, JSON.stringify(values[property]));

          } else {
            
            formData.append(property, values[property]);
          
          }

        }

        formData.set('description', description);
        /* formData.set('issuingDate', new Date(issuingDate).toISOString());
        formData.set('distributionClosingDate', new Date(distributionClosingDate).toISOString()); */

        formData.set('image', image);
        
        if (stateImage) {
          newFormData.set('state_image', stateImage);
        }

        const { message } = await EditAssetApi(ID, formData);
        toast.success(message ?? 'Asset edited successufully');

        const response = await EditStateImage(ID, newFormData);
        toast.success(message ?? 'Sub image edited successufully');
      } catch (error: any) {
        toast.error(error?.response?.data?.message ?? 'An error has occurred');
      }
      toast.dismiss(ToastID);
      await refetch();
    }
  });

  console.log('end')
  console.log(validation.initialValues)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Assets" breadcrumbItem="Edit" />

          <Row>
            <Col xl={12}>
              {loading && (
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              )}
              {!loading && (
                <React.Fragment>
                  <AssetPriceHistory
                    show={isPriceHistoryModalOpen}
                    title={'Price history for ' + data?.data.token?.name}
                    pricesList={data?.data.values?.chart ?? []}
                    onCloseClick={() =>
                      setIsPriceHistoryModalOpen(!isPriceHistoryModalOpen)
                    }
                  />
                  <Card>
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <CardHeader>
                        <Row>
                          <Col>
                            <h4 className="card-title">
                              Edit asset <span>"{data.data.name}"</span>
                            </h4>
                            <p className="card-title-desc">
                              Associated token:{' '}
                              <span>
                                {data.data.token.name} on{' '}
                                {data.data.token.contractAddress === 'pending'
                                  ? '...not yet available'
                                  : data.data.token.contractAddress}
                              </span>
                            </p>
                          </Col>
                          <Col>
                            <Button
                                disabled={validation.isSubmitting}
                                color="danger"
                                onClick={() => setIsModalOpen(true)}
                              >
                                Delete
                            </Button>
                            <DeleteModal
                              show={isModalOpen}
                              onCloseClick={() => setIsModalOpen(false)}
                              onDeleteClick={() => handleAssetDelete()}
                            />
                          </Col>
                          <Col>
                            <div style={{ textAlign: 'right' }}>
                              <Button
                                disabled={validation.isSubmitting}
                                color="primary"
                                type="submit"
                              >
                                Save changes
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <FormGroup className="py-1">
                          <h3>Asset</h3>
                          <Row>
                            <Col className="py-3">
                              <Label htmlFor="validationCustom01">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ''}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col className="py-3">
                              <Label htmlFor="validationCustom20">Subtitle</Label>
                              <Input
                                name="subtitle"
                                type="text"
                                className="form-control"
                                id="validationCustom20"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.subtitle || ''}
                                invalid={
                                  validation.touched.subtitle &&
                                  validation.errors.subtitle
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.subtitle &&
                              validation.errors.subtitle ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.subtitle}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col className="py-3">
                              <Label htmlFor="validationCustom20">Devedor (Internal field)</Label>
                              <Input
                                name="devedor"
                                type="text"
                                className="form-control"
                                id="validationCustom20"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.devedor || ''}
                                invalid={
                                  validation.touched.devedor &&
                                  validation.errors.devedor
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.devedor &&
                              validation.errors.devedor ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.devedor}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} sm={12} md={3} className="py-3">
                              <Label htmlFor="validationCustom02">Cover Image</Label>
                              <Input type='file' onChange={handleImageChange} />
                            </Col>
                            <Col xs={12} sm={12} md={3} className='text-center py-3 align-items-center'>
                              <img className='img-thumbnail w-50' src={data.data.image} alt="" />
                            </Col>
                            <Col xs={12} sm={12} md={3} className="py-3">
                              <Label htmlFor="validationCustom02">Asset Type Image</Label>
                              <Input type='file' onChange={handleStateImageChange} />
                            </Col>
                            <Col xs={12} sm={12} md={3} className='text-center py-3 align-items-center'>
                              <img className='img-thumbnail w-50' src={data.data.state_image} alt="" />
                            </Col>
                          </Row>

                          <Row>
                            <Col className="py-3">
                              <Label htmlFor="validationCustom02">
                                Description
                              </Label>
                              {editorLoading && (
                                <Card
                                  style={{
                                    display: 'grid',
                                    placeContent: 'center',
                                    padding: '10px 0'
                                  }}
                                >
                                  <Spinner color="primary"></Spinner>
                                </Card>
                              )}
                              <Editor
                                id="validationCustom02"
                                initialValue={data.data.description}
                                apiKey={process.env.REACT_APP_API_KEY_TINY}
                                onInit={(evt, editor) => {
                                  // @ts-expect-error
                                  editorRef.current = editor;
                                  setEditorLoading(false);
                                }}
                                init={{
                                  height: 300,
                                  menubar: 'edit format',
                                  statusbar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | hr',
                                  content_style:
                                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <h3>Upload documents</h3>
                            <Col className="py-3">
                              <Label>Documents</Label>
                              <Input type="file"
                                    accept="application/pdf"
                                    onChange={handleFileChange}
                              />
                              <div className='d-flex gap-2'>
                                {signDocuments && <h6 className='pt-3'>{signDocuments.name}</h6>}
                              </div>
                            </Col>
                            <div className=''>
                            <Label>Documents uploaded</Label>
                              {documents && documents.length > 0 && (
                                <div className='d-flex flex-row gap-3 flex-wrap'>
                                  {documents.map((doc, index) => (
                                    <div key={index} className='upload_box p-3' style={{borderRadius: '20px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)'}}>
                                      <a href={doc.link}>{doc.name}</a>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </Row>
                        </FormGroup>

                        <hr className="my-4" />

                        <FormGroup className="py-1">
                          <h3>Token</h3>

{/*                           <Row className="py-3">
                            <Col md={4}>
                              <Label htmlFor="validationCustom07">
                                Issuing Date
                              </Label>
                              <Input
                                name="issuingDate"
                                type="date"
                                className="form-control"
                                id="validationCustom07"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.issuingDate || ''}
                                invalid={
                                  validation.touched.issuingDate &&
                                  validation.errors.issuingDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.issuingDate &&
                              validation.errors.issuingDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.issuingDate}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={4}>
                              <Label htmlFor="validationCustom08">
                                Distribution Closing Date
                              </Label>
                              <Input
                                name="distributionClosingDate"
                                type="date"
                                className="form-control"
                                id="validationCustom08"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.distributionClosingDate || ''}
                                invalid={
                                  validation.touched.distributionClosingDate &&
                                  validation.errors.distributionClosingDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.distributionClosingDate &&
                              validation.errors.distributionClosingDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.distributionClosingDate}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={4}>
                              <Label htmlFor="validationCustom08">
                                Due Date
                              </Label>
                              <Input
                                name="dueDate"
                                type="date"
                                className="form-control"
                                id="validationCustom08"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.dueDate || ''}
                                invalid={
                                  validation.touched.dueDate &&
                                  validation.errors.dueDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.dueDate &&
                              validation.errors.dueDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.dueDate}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </Row> */}
                          <Row className="py-3">
                            <Col xl={1} lg={12}>
                              <div className="form-check">
                                <Input
                                  name="active"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="activeCheck"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  checked={validation.values.active}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="activeCheck"
                                >
                                  Active
                                </Label>
                              </div>
                            </Col>
                            <Col xl={1} lg={12}>
                              <div className="form-check">
                                <Input
                                  name="visible"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="visibleCheck"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  checked={validation.values.visible}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="visibleCheck"
                                >
                                  Visible
                                </Label>
                              </div>
                            </Col>
                            <Col xl={3} lg={12}>
                              <div className="form-check">
                                <Input
                                  name="reserving"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="reservingCheck"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  checked={validation.values.reserving}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="reservingCheck"
                                >
                                  Pre order Mode
                                </Label>
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                        {/* Summary */}
                      <FormGroup>
                      <h3>Summary</h3>
                      <Row className="py-3">
                          <Col md="4">
                            <Label htmlFor="validationCustom09">
                              Summary
                            </Label>
                            <Input
                              name="text[summary]"
                              type="textarea"
                              id="validationCustom09"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.summary || ''}
                              invalid={
                                validation.touched.text?.summary &&
                                validation.errors.text?.summary
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.summary &&
                            validation.errors.text?.summary ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.summary}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md={4}>
                            <Label htmlFor="validationCustom10">Profitability</Label>
                            <Input
                              name="text[profitability]"
                              type="textarea"
                              className="form-control"
                              id="validationCustom10"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.profitability || ''}
                              invalid={
                                validation.touched.text?.profitability &&
                                validation.errors.text?.profitability
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.profitability &&
                            validation.errors.text?.profitability ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.profitability}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md={4}>
                            <Label htmlFor="validationCustom11">
                              Due Diligence
                            </Label>
                            <Input
                              name="text[dueDiligence]"
                              type="textarea"
                              className="form-control"
                              id="validationCustom11"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.dueDiligence || ''}
                              invalid={
                                validation.touched.text?.dueDiligence &&
                                validation.errors.text?.dueDiligence
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.dueDiligence &&
                            validation.errors.text?.dueDiligence ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.dueDiligence}
                              </FormFeedback>
                            ) : null}
                          </Col>
                      </Row>
                      </FormGroup>
                      <hr className="my-4" />
                      {/* Yields and Returns */}
                      <FormGroup>
                      <h3>Yields and Returns</h3>
                      <Row className="py-3">
                        <Col md={4}>
                          <Label htmlFor="validationCustom13">
                            Estimated Yield: Optimistic
                          </Label>
                          <Input
                            name="yields[optimistic]"
                            type="text"
                            id="validationCustom13"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation?.values?.yields?.optimistic ?? ''}
                            invalid={
                              validation.touched.yields?.optimistic &&
                              validation.errors.yields?.optimistic
                                ? true
                                : false
                            }
                          />
                          {validation.touched.yields?.optimistic &&
                          validation.errors.yields?.optimistic ? (
                            <FormFeedback type="invalid">
                              {validation.errors.yields?.optimistic}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom14">Estimated Yield: Base</Label>
                          <Input
                            name="yields[base]"
                            type="text"
                            className="form-control"
                            id="validationCustom14"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation?.values?.yields?.base ?? ''}
                            invalid={
                              validation.touched.yields?.base &&
                              validation.errors.yields?.base
                                ? true
                                : false
                            }
                          />
                          {validation.touched.yields?.base &&
                          validation.errors.yields?.base ? (
                            <FormFeedback type="invalid">
                              {validation.errors.yields?.base}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={4}>
                          <Label htmlFor="validationCustom15">
                            Estimated Yield: Pessimistic
                          </Label>
                          <Input
                            name="yields[worst]"
                            type="text"
                            className="form-control"
                            id="validationCustom15"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation?.values?.yields?.worst ?? ''}
                            invalid={
                              validation.touched.yields?.worst &&
                              validation.errors.yields?.worst
                                ? true
                                : false
                            }
                          />
                          {validation.touched.yields?.worst &&
                          validation.errors.yields?.worst ? (
                            <FormFeedback type="invalid">
                              {validation.errors.yields?.worst}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      </FormGroup>
                      <hr className="my-4" />
                      {/* Operation flows */}
                      <FormGroup>
                      <h3>Operation flows and Risks</h3>
                      <Row className="py-3">
                        <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Select the step where the token is:
                          </Label>
                          <select
                            name="text[paymentPeriod]"
                            id="validationCustom19"
                            className={`form-control form-select`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.text?.paymentPeriod || ""}
                          >
                            <option value={undefined} selected>
                              Select a Step
                            </option>
                            {paymentPeriod.map((operation, index) => (
                              <option key={index} value={operation}>
                                {operation}
                              </option>
                            ))}
                          </select>
                          {validation.touched.text?.paymentPeriod && validation.errors.text?.paymentPeriod && (
                            <div className="invalid-feedback">{validation.errors.text?.paymentPeriod}</div>
                          )}
                        </Col>
                        <Col md="4">
                            <Label htmlFor="validationCustom13">
                              Risk type
                            </Label>
                            <Input
                              name="text[riskType]"
                              type="text"
                              id="validationCustom20"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.riskType || ''}
                              invalid={
                                validation.touched.text?.riskType &&
                                validation.errors.text?.riskType
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.riskType &&
                            validation.errors.text?.riskType ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.riskType}
                              </FormFeedback>
                            ) : null}
                        </Col>
                        {/* <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Select the Risk Type:
                          </Label>
                          <select
                            name="text[riskType]"
                            id="validationCustom19"
                            className={`form-control`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.text?.riskType || ""}
                          >
                            <option value={undefined} selected>
                              Select a Risk Type
                            </option>
                            {riskType.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                          {validation.touched.text?.riskType && validation.errors.text?.riskType && (
                            <div className="invalid-feedback">{validation.errors.text?.riskType}</div>
                          )}
                        </Col> */}
                        <Col md="4">
                          <Label htmlFor="validationCustom13">
                            Select the risk level:
                          </Label>
                          <select
                            name="text[riskLevel]"
                            id="validationCustom21"
                            className={`form-control  form-select`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.text?.riskLevel || ""}
                          >
                            {riskLevel.map((level, index) => (
                              <option key={index} value={level}>
                                {level}
                              </option>
                            ))}
                          </select>
                          {validation.touched.text?.riskLevel && validation.errors.text?.riskLevel && (
                            <div className="invalid-feedback">{validation.errors.text?.riskLevel}</div>
                          )}
                        </Col>
                      </Row>
                      <Row className='py-3'>
                        <Col md="12">
                            <Label htmlFor="validationCustom13">
                              Risk description
                            </Label>
                            <Input
                              name="text[riskDesc]"
                              type="textarea"
                              id="validationCustom34"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.riskDesc || ''}
                              invalid={
                                validation.touched.text?.riskDesc &&
                                validation.errors.text?.riskDesc
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.riskDesc &&
                            validation.errors.text?.riskDesc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.riskDesc}
                              </FormFeedback>
                            ) : null}
                        </Col>
                      </Row>
                      </FormGroup>
                      <hr className="my-4" />
                      {/* Fees */}
                      <FormGroup>
                      <h3>Fees</h3>
                      <Row className='py-3'>
                        <Col md="6">
                            <Label htmlFor="validationCustom13">
                              Distribution fees %
                            </Label>
                            <Input
                              name="fees[distribution]"
                              type="text"
                              id="validationCustom22"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fees?.distribution || ''}
                              invalid={
                                validation.touched.fees?.distribution &&
                                validation.errors.fees?.distribution
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fees?.distribution &&
                            validation.errors.fees?.distribution ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fees?.distribution}
                              </FormFeedback>
                            ) : null}
                        </Col>
                        <Col md="6">
                            <Label htmlFor="validationCustom13">
                              Administration fees %
                            </Label>
                            <Input
                              name="fees[administration]"
                              type="text"
                              id="validationCustom23"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fees?.administration || ''}
                              invalid={
                                validation.touched.fees?.administration &&
                                validation.errors.fees?.administration
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fees?.administration &&
                            validation.errors.fees?.administration ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fees?.administration}
                              </FormFeedback>
                            ) : null}
                        </Col>
                      </Row>
{/*                       <Row>
                        <Col md="6">
                            <Label htmlFor="validationCustom13">
                              Exit Tax
                            </Label>
                            <Input
                              name="text[exitTax]"
                              type="text"
                              id="validationCustom30"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.exitTax || ''}
                              invalid={
                                validation.touched.text?.exitTax &&
                                validation.errors.text?.exitTax
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.exitTax &&
                            validation.errors.text?.exitTax ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.exitTax}
                              </FormFeedback>
                            ) : null}
                        </Col>
                        <Col md="6">
                            <Label htmlFor="validationCustom13">
                              Exit Tax %
                            </Label>
                            <Input
                              name="text[exitTaxPerc]"
                              type="text"
                              id="validationCustom31"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.exitTaxPerc || ''}
                              invalid={
                                validation.touched.text?.exitTaxPerc &&
                                validation.errors.text?.exitTaxPerc
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.exitTaxPerc &&
                            validation.errors.text?.exitTaxPerc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.exitTaxPerc}
                              </FormFeedback>
                            ) : null}
                        </Col>
                      </Row>
                      <Row className='py-3'>
                        <Col md="3">
                            <Label htmlFor="validationCustom13">
                              Rendit Tax Min k
                            </Label>
                            <Input
                              name="text[renditTaxMin]"
                              type="text"
                              id="validationCustom32"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.renditTaxMin || ''}
                              invalid={
                                validation.touched.text?.renditTaxMin &&
                                validation.errors.text?.renditTaxMin
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.renditTaxMin &&
                            validation.errors.text?.renditTaxMin ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.renditTaxMin}
                              </FormFeedback>
                            ) : null}
                        </Col>
                        <Col md="3">
                            <Label htmlFor="validationCustom13">
                              Rendit Tax Min k %
                            </Label>
                            <Input
                              name="text[renditTaxMin35Perc]"
                              type="text"
                              id="validationCustom36"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.renditTaxMin35Perc || ''}
                              invalid={
                                validation.touched.text?.renditTaxMin35Perc &&
                                validation.errors.text?.renditTaxMin35Perc
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.renditTaxMin35Perc &&
                            validation.errors.text?.renditTaxMin35Perc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.renditTaxMin35Perc}
                              </FormFeedback>
                            ) : null}
                        </Col>
                        <Col md="3">
                            <Label htmlFor="validationCustom13">
                              Rendit Tax Max k
                            </Label>
                            <Input
                              name="text[renditTaxMax]"
                              type="text"
                              id="validationCustom37"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.renditTaxMax || ''}
                              invalid={
                                validation.touched.text?.renditTaxMax &&
                                validation.errors.text?.renditTaxMax
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.renditTaxMax &&
                            validation.errors.text?.renditTaxMax ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.renditTaxMax}
                              </FormFeedback>
                            ) : null}
                        </Col>
                        <Col md="3">
                            <Label htmlFor="validationCustom13">
                              Rendit Tax Max k %
                            </Label>
                            <Input
                              name="text[renditTaxMax35Perc]"
                              type="text"
                              id="validationCustom35"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.text?.renditTaxMax35Perc || ''}
                              invalid={
                                validation.touched.text?.renditTaxMax35Perc &&
                                validation.errors.text?.renditTaxMax35Perc
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.text?.renditTaxMax35Perc &&
                            validation.errors.text?.renditTaxMax35Perc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.text?.renditTaxMax35Perc}
                              </FormFeedback>
                            ) : null}
                        </Col>
                      </Row> */}
                      </FormGroup>
                      {/* Category */}
                     <FormGroup>
                      <h3>Category</h3>
                      <Row className='py-3'>
                      <Col md="12">
                          <Label htmlFor="validationCustom13">
                            Select a Category:
                          </Label>
                          <select
                            name="categories"
                            id="validationCustom19"
                            className={`form-control form-select`}
                            onChange={(e) => {
                              const selectedValue = e.target.value === "" ? null : e.target.value;
                              validation.setFieldValue("categories", selectedValue);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values?.categories}
                          >
                            <option value="" selected disabled>
                              Select a Category
                            </option>
                            {categoryData?.data?.map((category, index) => (
                              <option key={index} value={category._id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                          {validation.touched?.categories && validation.errors?.categories && (
                            <div className="invalid-feedback">{validation.errors?.categories}</div>
                          )}
                        </Col>
                      </Row>
                      </FormGroup>
                      </CardBody>
                    </Form>
                  </Card>

                  <Card className="mt-4">
                    <CardHeader>
                      <Row>
                        <Col>
                          <h4 className="card-title">Change asset price</h4>
                          <p
                            className="card-title-desc"
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline'
                            }}
                            onClick={() => setIsPriceHistoryModalOpen(true)}
                          >
                            Click here to see the recorded price history!
                          </p>
                        </Col>
                        <Col>
                          <div style={{ textAlign: 'right' }}>
                            <Button
                              disabled={validation.isSubmitting}
                              color="primary"
                              onClick={() => handlePriceChange()}
                            >
                              Save new price
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="mb-3">
                          <Input
                            type="number"
                            placeholder="1000"
                            className="form-control"
                            onChange={(e) => setCurrentPrice(e.target.value)}
                            value={currentPrice}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditAsset;
