import React from 'react';
import { Redirect } from 'react-router-dom';

//Dashboard
import Dashboard from '../pages/Dashboard';

import Multisig from 'src/pages/Multisignature';

//Assets
import AllAssets from 'src/pages/Assets/All';
import CreateAsset from 'src/pages/Assets/Create';
import EditAsset from 'src/pages/Assets/Edit';

// Category
import AllCategories from 'src/pages/Categories/All';
import CreateCategory from 'src/pages/Categories/Create';
import EditCategory from 'src/pages/Categories/Edit';

// Banking
import AllSubaccounts from 'src/pages/Banking/All/Index';
import SingleSubaccount from 'src/pages/Banking/Single/Index';

//Authentication pages
import Login from 'src/pages/Authentication/Login';
import Logout from 'src/pages/Authentication/Logout';
import userProfile from 'src/pages/Authentication/user-profile';

//Users
import AllUsers from 'src/pages/Users/All/Index';
import GetUser from 'src/pages/Users/Single/Index';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: '/profile', component: userProfile },

  //dashboard
  { path: '/dashboard', component: Dashboard },

  { path: '/multisig', component: Multisig },

  { path: '/assets', component: AllAssets },
  { path: '/assets/create', component: CreateAsset },
  { path: '/assets/:id', component: EditAsset },

  { path: '/categories', component: AllCategories },
  { path: '/categories/create', component: CreateCategory },
  { path: '/categories/:id', component: EditCategory },

  { path: '/users', component: AllUsers },
  { path: '/users/:id', component: GetUser },

  { path: '/banking', component: AllSubaccounts },
  { path: '/banking/:code', component: SingleSubaccount },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: '/login', component: Login },
  { path: '/logout', component: Logout }
];

export { userRoutes, authRoutes };
