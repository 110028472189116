import React, { useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';

import { CreateNewCategory } from 'src/services/api';

import toast from 'react-hot-toast';

import { useHistory } from 'react-router-dom';

import ImageUpload from '../components/ImageUpload';

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Form
} from 'reactstrap';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const CreateCategory = () => {
  const [editorLoading, setEditorLoading] = useState(true);
  const editorRef = useRef(null);

  const History = useHistory();

  document.title = 'Create Category | Goyield Backoffice';

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      image: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      const ToastID = toast.loading('Please wait...');

      let formData = new FormData();
      let description = '';

      if (editorRef.current) {
        // @ts-expect-error
        description = editorRef.current.getContent();
      }

      try {
        formData.append('name', values.name);
        formData.append('description', description);
        formData.append('image', values.image[0]);

        const res = await CreateNewCategory(formData);
        toast.success(res.message ?? 'Created new category');
        History.push({ pathname: '/categories/' + res.data._id });
      } catch (error: any) {
        toast.error(error?.response?.data?.message ?? 'An error has occurred');
      }
      toast.dismiss(ToastID);
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Categories" breadcrumbItem="Create" />

          <Row>
            <Col xl={12}>
              <Card>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <CardHeader>
                    <Row>
                      <Col>
                        <h4 className="card-title">Create a new category</h4>

                        <p className="card-title-desc">
                          All fields are required
                        </p>
                      </Col>
                      <Col>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            disabled={validation.isSubmitting}
                            color="primary"
                            type="submit"
                          >
                            Create new category
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Name</Label>
                          <Input
                            name="name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <Label>Image</Label>
                          <ImageUpload
                            setFieldValue={validation.setFieldValue}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="py-3">
                        <Label htmlFor="validationCustom02">Description</Label>
                        {editorLoading && (
                          <Card
                            style={{
                              display: 'grid',
                              placeContent: 'center',
                              padding: '10px 0'
                            }}
                          >
                            <Spinner color="primary"></Spinner>
                          </Card>
                        )}
                        <Editor
                          id="validationCustom02"
                          apiKey={process.env.REACT_APP_API_KEY_TINY}
                          onInit={(evt, editor) => {
                            // @ts-expect-error
                            editorRef.current = editor;
                            setEditorLoading(false);
                          }}
                          init={{
                            height: 300,
                            menubar: 'edit format',
                            statusbar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | hr',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCategory;
