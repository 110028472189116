import { all, fork } from 'redux-saga/effects';

//Layout
import LayoutSaga from './layout/saga';

//Login
import authSaga from './auth/login/saga';

//User Profile
import ProfileSaga from './auth/profile/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(authSaga),
    fork(ProfileSaga)
  ]);
}
