import { combineReducers } from 'redux';

// Layout
import Layout from './layout/reducer';

//login
import login from './auth/login/reducer';

// User Profile
import profile from './auth/profile/reducer';

const rootReducer = combineReducers({
  Layout,
  login,
  profile
});

export default rootReducer;
