import Icon from '@ailibs/feather-react-ts/dist/Icon';
import React, { useContext } from 'react';
import { toast } from 'react-hot-toast';

import { Row, Col } from 'reactstrap';
import BlockchainContext from '../BlockchainContext';

import { completeBuyAsset } from 'src/services/api';

const SingleRequest = ({
  reqId,
  beneficiary,
  amount,
  timestamp,
  createdBy,
  votesCount,
  status,
  signers,
  alreadyVoted
}) => {
  const Context = useContext(BlockchainContext);
  const { accounts, contract, factoryContract } = Context;

  const handleApprove = async (id) => {
    const ToastID = toast.loading(
      'Please continue on the notification window... This might take a while to process!'
    );
    try {
      const res = await contract.methods.approveRequest(id).send({
        from: accounts[0]
      });
      toast.success(res?.message ?? 'Request approved successfully');
    } catch (e) {
      toast.error(e?.message ?? 'An error has occurred');
    }
    toast.dismiss(ToastID);
  };

  const handleSend = async (id) => {
    const ToastID = toast.loading(
      'Please wait... This might take a while to process!'
    );
    try {
      const payload = {
        tokenAddress: contract.options.address,
        requestId: id
      };

      const res = await completeBuyAsset(payload);
      toast.success(res?.message ?? 'Request sent successfully');
    } catch (e) {
      toast.error(e?.message ?? 'An error has occurred');
    }
    toast.dismiss(ToastID);
  };

  return (
    <div className="py-2 px-4">
      <Row className="pb-2">
        <Col>
          <h3>
            Request ID: <strong>{reqId}</strong>
          </h3>
          <h6>{!!alreadyVoted ? 'Already voted' : 'Pending vote'}</h6>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <div>
            <button
              disabled={alreadyVoted}
              style={{ display: 'inline-flex', alignItems: 'center' }}
              className="btn btn-primary mr-2"
              onClick={() => handleApprove(reqId)}
            >
              <Icon size={16} name="save" />
              <span className="mx-1">Approve</span>
            </button>
            <button
              disabled={votesCount !== signers || status != 0}
              style={{ display: 'inline-flex', alignItems: 'center' }}
              className="btn btn-primary mx-2"
              onClick={() => handleSend(reqId)}
            >
              <Icon size={16} name="send" />
              <span className="mx-1">Send</span>
            </button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={6}>
          <div>
            Beneficiary: <strong>{beneficiary}</strong>
          </div>
          <div>
            Amount: <strong>{amount / 10 ** 18}</strong>
          </div>
          <div>
            Approvals count:{' '}
            <strong>
              {votesCount}/{signers}
            </strong>
          </div>
        </Col>
        <Col xl={6}>
          <div>
            Created by: <strong>{createdBy}</strong>
          </div>
          <div>
            Created at:{' '}
            <strong>{new Date(timestamp * 1000).toLocaleString()}</strong>
          </div>
          <div>
            Status: <strong>{status == 0 ? 'Pending' : 'Sent'}</strong>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SingleRequest;
