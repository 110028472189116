import React from 'react';

import { useHistory } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import { useState, useEffect, } from 'react';


import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Button
} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import Icon from '@ailibs/feather-react-ts/dist/Icon';

import useAxios from 'axios-hooks';

import { GET_ALL_ASSETS, StatusUpdate } from 'src/services/api';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//Import Breadcrumb
import Breadcrumb from 'src/components/Common/Breadcrumb';

const AllAssets = () => {
  document.title = 'All Assets | Goyield Backoffice';

  const History = useHistory();

  const [page, setPage] = useState(1);
  const num = 5;
  const [count, setCount] = useState(0)

  /* const [{ data, loading, error }, refetch] = useAxios(GET_ALL_ASSETS); */
  const [{ data, loading, error }, refetch] = useAxios({
    url: GET_ALL_ASSETS,
    params: {
      page,
      num
    }
  });

  useEffect(() => {
    if(data?.count) {
      setCount(data.count);
    }
  }, [data]);

  const handleNextPage = () => {
    if (page < Math.ceil(data?.count / num)) {
      setPage((prevPage) => prevPage + 1); // aumenta la pagina di 1
      refetch();
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1); // diminuisce la pagina di 1 solo se la pagina è maggiore di 1
      refetch();
    }
  };

  
  /* const handleStatusUpdate = async () => {
    const ToastID = toast.loading('Please wait...');

    try {
      await StatusUpdate();
      await refetch();
      toast.success('Showing updated status');
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? 'An error has occurred');
    }
    toast.dismiss(ToastID);
  }; */

  const handleRowClick = (ID: string) => {
    History.push({
      pathname: `/assets/${ID}`
    });
  };

  const RenderTableBody = () => {
    if (!loading && !!data) {
      const filteredData = data?.assets.filter((row: any) => !row.deleted);
      console.log(filteredData);
      return (
        <Tbody>
          {filteredData.map((row: any) => {
            return <TableRow key={row._id} data={row} />;
          })}
        </Tbody>
      );
    } else if (!loading && !!error) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="8">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    } else {
      return null;
    }
  };

/*   const RenderTableBody = () => {
    if (!loading && !!data) {
      return (
        <Tbody>
          {data.map((row: any) => {
            return <TableRow key={row._id} data={row} />;
          })}
        </Tbody>
      );
    } else if (!loading && !!error) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="8">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    } else {
      return null;
    }
  }; */

  const TableRow = (props: any) => {
    const {
      _id,
      visible,
      active,
      name,
      description,
      values,
      token,
      createdAt,
      updatedAt,
      schedule
    } = props.data;
    const {issuingDate, distributionClosingDate, dueDate} = schedule;
    const { name: tokenName, symbol, supply, status } = token;

    let price;

    if (values?.chart?.length > 0) {
      price = values.chart[values.chart.length - 1]?.value;
    }

    return (
      <Tr style={{ cursor: 'pointer' }} onClick={() => handleRowClick(_id)}>
        <Td>
          <div className="text-center">
            {active ? (
              <Icon name="check" color="green" />
            ) : (
              <Icon name="x" color="red" />
            )}
          </div>
        </Td>
        <Td>
          <div className="text-center">
            {visible ? (
              <Icon name="check" color="green" />
            ) : (
              <Icon name="x" color="red" />
            )}
          </div>
        </Td>
        <Td>
          <strong>{name}</strong>
        </Td>
        <Td>
          <div
            dangerouslySetInnerHTML={{
              __html: description
            }}
          ></div>
        </Td>
        <Td>{price}</Td>
        <Td>
          <div>{new Date(issuingDate).toLocaleDateString()}</div>
          <div>{new Date(distributionClosingDate).toLocaleDateString()}</div>
        </Td>
        <Td>
          <div>{new Date(createdAt).toLocaleDateString()}</div>
          <div>{new Date(updatedAt).toLocaleDateString()}</div>
        </Td>
        <td style={{ minWidth: '300px' }}>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Name: <strong>{tokenName}</strong>
            </span>
            <span>
              Symbol: <strong>{symbol}</strong>
            </span>
          </span>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Supply: <strong>{supply}</strong>
            </span>
            <span>
              Status: <strong>{status.toUpperCase()}</strong>
            </span>
          </span>
        </td>
      </Tr>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Assets" />

          <Row>
            <Col>
              {loading && (
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              )}
              {!loading && (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h4 className="card-title">All assets</h4>
                        <p className="card-title-desc">
                          Click on a row to edit the selected asset
                        </p>
                      </Col>
{/*                       <Col>
                        <div style={{ textAlign: 'right' }}>
                          <Button color="primary" onClick={handleStatusUpdate}>
                            Update tokens status
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Active
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Visible
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Name
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Description
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Current price
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Start/End
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="6"
                              >
                                Created/Updated
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="3"
                              >
                                Token
                              </Th>
                            </Tr>
                          </Thead>

                          <RenderTableBody />
                        </Table>
                        <div className='pagination mt-5 d-flex justify-content-center align-items-center'>
                          <button className='border-0 bg-transparent p-2' onClick={handlePrevPage}>ᐸ</button>
                          <h5 className='mx-2 my-0'>Results: {count}</h5>
                          <h5 className='mx-2 my-0'>Page: {page}</h5>
                          <button className='border-0 bg-transparent p-2' onClick={handleNextPage}>ᐳ</button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllAssets;
