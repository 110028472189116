import React from 'react';

import { Route, useHistory } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import { useState, useEffect, } from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Button
} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import Icon from '@ailibs/feather-react-ts/dist/Icon';

import useAxios from 'axios-hooks';

import {GET_ALL_SUBACCOUNTS, GET_ALL_TRANSACTIONS} from 'src/services/api';

import { PieChart, Pie, Sector, LineChart, Line, XAxis, CartesianGrid, YAxis, Tooltip } from 'recharts';
import { BarChart, Bar, Legend } from 'recharts';

import { GET_USERS } from 'src/services/api';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//Import Breadcrumb
import Breadcrumb from 'src/components/Common/Breadcrumb';
import SingleUser from '../Single/Index';

const AllSubaccounts = () => {
  document.title = 'All Banking Subaccounts | Goyield Backoffice';

  const [{ data, loading, error }, refetch] = useAxios({
    url: GET_ALL_SUBACCOUNTS
  });
  
  const RenderTableBody = () => {
    if (!loading && !!data) {
      return (
        <Tbody>
          {data.data.data.map((row: any) => {
            return <TableRow key={row.username} data={row} />;
          })}
        </Tbody>
      );
    } else if (!loading && !!error) {
      return (
        <Tbody>
          <Tr>
            <Td colspan="8">An error has occurred</Td>
          </Tr>
        </Tbody>
      );
    } else {
      return null;
    }
  };

  const TableRow = (props: any) => {
    const {
      username,
      accountName
    } = props.data;

    const history = useHistory();

    return (
      <Tr style={{ cursor: 'pointer' }} onClick={() => history.push(`/banking/${username}`)}>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{username ?? '-'}</strong>
        </Td>
        <Td>
          <strong style={{ display: 'flex', justifyContent: 'center' }}>{accountName ?? '-'}</strong>
        </Td>
      </Tr>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Banking Accounts" />

          <Row>
            <Col>
              {loading && (
                <Card
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    padding: '10px 0'
                  }}
                >
                  <Spinner color="primary"></Spinner>
                </Card>
              )}
              {!loading && (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h4 className="card-title">All banking linked accounts</h4>
                        <p className="card-title-desc">
                          Click on a row to see each account's transactions
                        </p>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Username
                              </Th>
                              <Th
                                style={{ textAlign: 'center' }}
                                data-priority="1"
                              >
                                Account Name
                              </Th>
                            </Tr>
                          </Thead>
                          <RenderTableBody />
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllSubaccounts;
